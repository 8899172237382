/*
Author       : Dreamguys
Template Name: PreClinic - Medical and Hospital Admin Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Header
6. Sidebar
7. Content
8. Login
9. Dashboard
10. Activity
11. Select2
12. Nav tabs
13. Holidays
14. Edit Profile
15. Chat
16. Focus Label
17. Leave
18. Employee
19. Events
20. Profile
21. Notifications
22. Roles & Permissions
23. Chat Right Sidebar
24. Invoice
25. Doctors
26. Add Doctor
27. Payslip
28. Attendance
29. Inbox
30. Mail View
31. Blog
32. Blog View
33. UI Kit
34. Error
35. Lock Screen
36. Voice call
37. Video Call
38. Incoming call
39. Notification settings
40. Chat Sidebar
41. Gallery
42. Settings
43. Responsive

========================================*/

/*-----------------
	1. General
-----------------------*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600");

html {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #333448;
  background: linear-gradient(0deg,
      rgba(51, 53, 72, 0.05),
      rgba(51, 53, 72, 0.05)),
    #ffffff;
  overflow-x: hidden;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 1.875rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 0.875rem;
}

h6,
.h6 {
  font-size: 0.75rem;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: #009ce7;
  outline: none;
}

.form-control {
  box-shadow: none;
  padding: 10px 15px;
  font-size: 14px;
  min-height: 45px;
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
}

.form-control::placeholder {
  font-size: 13px;
}

.form-control:focus {
  border-color: #2e37a4;
  box-shadow: none;
  outline: 0 none;
}

a {
  color: #2e37a4;
  text-decoration: none;
}

input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

input,
button {
  outline: none;
}

input[type="file"] {
  height: auto;
  padding: 6px 0.75rem;
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea.form-control {
  resize: vertical;
}

.navbar-nav>li {
  float: left;
}

.form-group {
  margin-bottom: 20px;
}

.display-block {
  display: block;
}

.btn.rounded {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.btn.focus,
.btn:focus {
  box-shadow: inherit !important;
}

.list-group-item {
  border: 1px solid #eaeaea;
}

/*-----------------
	2. Table
-----------------------*/

.table> :not(:first-child) {
  border: 0;
}

table.table td,
table.table th {
  border-color: #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.dataTables_length select {
  appearance: auto;
}

.table {
  color: #000;
  /* border: 1px solid #f0f0f0; */
}

.table.table-white {
  background-color: #fff;
}

.table>tbody>tr>td {
  font-weight: 300;
}

.table-striped>tbody>tr:nth-of-type(2n + 1) {
  background-color: #f6f6f6;
}

table.table td .avatar {
  margin: 0 5px 0 0;
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table.table td h2 a {
  color: #757575;
}

table.table td h2 a:hover {
  color: #009efb;
}

table.table td h2 span {
  color: #9e9e9e;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}

.custom-ant-table {
  zoom: 0% !important;
}

.custom-ant-table .ant-table-thead:hover {
  zoom: 0% !important;
}

/* .custom-ant-table .table-responsive  */

/*-----------------
	3. Helper Class
-----------------------*/

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.w-40 {
  width: 40px;
}

.block {
  display: block !important;
}

.text-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-muted-light {
  color: #aaa;
}

.card-box {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 32px;
  position: relative;
}

.card-title {
  color: #333448;
  font-size: 16px;
  margin-bottom: 20px;
}

.page-title {
  color: #333448;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 25px;
}

.page-sub-title {
  color: #565656;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}

.cal-icon:after {
  background: transparent url("../img/icons/calendar-icon.svg") no-repeat scroll 0 0;
  bottom: 0;
  content: "";
  display: block;
  height: 24px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 0;
  width: 24px;
  cursor: pointer;
}

.cal-icon {
  position: relative;
  width: 100%;
}

.time-icon:after {
  background: transparent url("../img/clock.png") no-repeat scroll 0 0;
  bottom: 0;
  content: "";
  display: block;
  height: 19px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 0;
  width: 19px;
}

.time-icon {
  position: relative;
  width: 100%;
}

.badge-success-border {
  border: 1px solid #55ce63;
  color: #55ce63;
  background-color: #fff;
  display: inline-block;
  min-width: 80px;
}

.badge-danger-border {
  border: 1px solid #f62d51;
  color: #f62d51;
  background-color: #fff;
  display: inline-block;
  min-width: 80px;
}

.badge-warning-border {
  border: 1px solid #ffbc34;
  color: #ffbc34;
  background-color: #fff;
  display: inline-block;
  min-width: 80px;
}

.badge-info-border {
  border: 1px solid #009efb;
  color: #009efb;
  background-color: #fff;
  display: inline-block;
  min-width: 80px;
}

.modal-footer.text-start {
  text-align: left;
}

.modal-footer.text-center {
  text-align: center;
}

.font-18 {
  font-size: 18px;
}

.border-right {
  border-right: 1px solid #e7e7e7;
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.btn {
  font-size: 0.875rem;
}

/*-----------------
	4. Bootstrap Classes
-----------------------*/

.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.rounded,
.circle {
  border-radius: 500px;
}

.bg-primary,
.badge-primary {
  background-color: #009efb !important;
}

.bg-success,
.badge-success {
  background-color: #55ce63 !important;
}

.bg-info,
.badge-info {
  background-color: #009efb !important;
}

.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}

.bg-danger,
.badge-danger {
  background-color: #f62d51 !important;
}

.bg-white {
  background-color: #fff;
}

.text-primary,
.dropdown-menu>li>a.text-primary {
  color: #00d3c7 !important;
}

.text-success,
.dropdown-menu>li>a.text-success {
  color: #55ce63;
}

.text-danger,
.dropdown-menu>li>a.text-danger {
  color: #f62d51;
}

.text-info,
.dropdown-menu>li>a.text-info {
  color: #009efb;
}

.text-warning,
.dropdown-menu>li>a.text-warning {
  color: #ffbc34;
}

.text-purple,
.dropdown-menu>li>a.text-purple {
  color: #7460ee;
}

.btn-primary {
  border-color: transparent;
  background-color: #2e37a4;
  border-radius: 8px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
  background-color: #00d3c7;
  border: 1px solid #00d3c7;
  color: #fff;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}

.btn-outline-primary {
  border-color: #009efb;
  color: #009efb;
}

.btn-outline-primary:hover {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}

.btn-outline-primary.active:not(:disabled):not(.disabled),
.btn-outline-primary:active:not(:disabled):not(.disabled),
.show>.btn-outline-primary.dropdown-toggle {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}

.btn-success {
  background-color: #55ce63;
  border: 1px solid #55ce63;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
  background: #4ab657;
  border: 1px solid #4ab657;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
  background: #4ab657;
  border: 1px solid #4ab657;
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-warning {
  background: #ffbc34;
  border: 1px solid #ffbc34;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-danger {
  background: #f62d51;
  border: 1px solid #f62d51;
}

.btn-danger:hover,
.btn-danger:focus {
  background-color: #e6294b;
  border: 1px solid #e6294b;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background-color: #00d3c7;
  border-color: #00d3c7;
}

.pagination>li>a,
.pagination>li>span {
  color: #2e37a4;
  font-size: 14px;
  padding: 5px 15px !important;
}

.page-item.active .page-link {
  background-color: #2e37a4;
  border-color: #2e37a4;
}

.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #fff;
}

.navbar-nav .open .dropdown-menu {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-group-xs>.btn,
.btn-xs {
  min-height: 22px;
  min-width: 22px;
}

.dropdown-menu {
  font-size: 13px;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  box-shadow: none;
}

.btn-link {
  color: #333;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.card {
  margin-bottom: 25px;
  box-shadow: none;
  border: 0;
  border-radius: 12px;
  width: 100%;
}

.card-title {
  margin-bottom: 1.125rem;
}

.dropdown-action .dropdown-toggle:after {
  display: none;
}

.badge.rounded-pill {
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
}

.btn.btn-rounded {
  border-radius: 50px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
}

.nav-tabs .nav-link {
  border-radius: 0;
}

.table.dataTable {
  border-collapse: collapse !important;
}

textarea.form-control {
  height: auto;
}

.progress-example .progress {
  margin-bottom: 1.5rem;
}

.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 15px;
}

.progress.progress-sm {
  height: 6px;
}

.progress.progress-md {
  height: 8px;
}

.progress.progress-lg {
  height: 18px;
}

.form-control.form-control-sm {
  padding: 0.25rem 0.5rem;
}

.card .card-header {
  background-color: rgba(255, 255, 255, 0.1);
  border: 0 none;
  font-size: 16px;
  line-height: 30px;
  padding: 15px;
}

.card .card-header .card-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 5px 0;
}

.table h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  vertical-align: middle;
}

.table h5+p {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
}

.page-link:focus {
  box-shadow: unset;
}

.col-form-label {
  font-size: 14px;
  font-weight: 500;
}

.input-group-text {
  height: 45px;
}

/*-----------------
	5. Header
-----------------------*/

.header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1039;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(204, 204, 204, 0.25);
}

.header .header-left {
  float: left;
  height: 70px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 220px;
  z-index: 1;
}

.logo {
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: flex-start;
}

.logo span {
  color: #2e37a4;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}

.header .navbar-nav .badge {
  position: absolute;
  right: 7px;
  top: 4px;
}

.header .dropdown-menu>li>a {
  position: relative;
}

.header .dropdown-toggle:after {
  display: none;
}

.page-title-box {
  border-radius: 0;
  height: 70px;
  margin-bottom: 0;
  padding: 14px 20px;
}

.page-title-box h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.user-menu {
  margin: 0;
  position: relative;
  z-index: 99;
}

.user-menu.nav>li>a {
  color: #fff;
  font-size: 16px;
  line-height: 70px;
  padding: 0 5px;
  height: 70px;
}

.user-menu.nav>li>a:hover,
.user-menu.nav>li>a:focus {
  background-color: #eee;
}

.user-img {
  display: inline-block;
  position: relative;
}

.header .user-img img {
  width: 40px;
  border-radius: 14px;
}

.user-img .status {
  border: 2px solid #fff;
  bottom: 0;
  height: 10px;
  position: absolute;
  right: 0;
  width: 10px;
}

.user-menu .user-img .status {
  bottom: 0px;
}

.user-menu .dropdown-menu {
  min-width: 145px;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  display: block;
  transform: translate(0px, 100px);
}

.user-menu .dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.table .dropdown-menu.show {
  padding: 0;
}

.dropdown-menu.show {
  padding: 0;
}

.table .dropdown-item {
  padding: 7px 10px;
}

.user-menu .bg-primary {
  background-color: #009efb !important;
}

.status {
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.status.online {
  background-color: #55ce63;
}

.status.offline {
  background-color: #f62d51;
}

.status.away {
  background-color: #faa937;
}

.top-nav-search {
  float: left;
  margin-left: 22px;
}

.top-nav-search form {
  margin-top: 13px;
  position: relative;
  width: 270px;
}

.top-nav-search .form-control {
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  border: 0;
  height: 40px;
  padding: 10px 15px 10px 40px;
}

.top-nav-search .btn {
  background-color: transparent;
  border-color: transparent;
  min-height: 45px;
  padding: 7px 10px;
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-nav-search .form-control::-webkit-input-placeholder {
  color: rgba(46, 55, 164, 0.3);
  font-weight: 500;
}

.top-nav-search .form-control::-moz-placeholder {
  color: rgba(46, 55, 164, 0.3);
  font-weight: 500;
}

.top-nav-search .form-control:-ms-input-placeholder {
  color: rgba(46, 55, 164, 0.3);
  font-weight: 500;
}

.top-nav-search .form-control::-ms-input-placeholder {
  color: rgba(46, 55, 164, 0.3);
  font-weight: 500;
}

.top-nav-search .form-control::placeholder {
  color: rgba(46, 55, 164, 0.3);
  font-weight: 500;
}

.theme-set .form-control {
  line-height: 29px;
}

.user-profile-list .user-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 10px !important;
}

.user-menu.nav li.user-profile-list a {
  line-height: 20px;
}

.user-names {
  padding-right: 10px;
  text-align: right;
}

.user-names h5 {
  color: #2e37a4;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.user-names span {
  color: rgba(46, 55, 164, 0.5);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.user-menu.nav li {
  margin-right: 12px;
  position: relative;
}

/*-----------------
	6. Sidebar
-----------------------*/
.sidebar {
  top: 90px;
  width: 273px;
  z-index: 1039;
  background-color: #fff;
  bottom: 0;
  margin-top: 0px;
  position: fixed;
  left: 0;
  transition: all 0.2s ease-in-out;
  border-top-right-radius: 40px;
  box-shadow: 5px 20px 14px rgba(46, 55, 164, 0.05);
  overflow-y: hidden;
  height: 100vh;
}

/* .custom-scrollbar {
  overflow: scroll !important;
  height: 100vh !important;
} */

.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar-inner {
  height: 100%;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul {
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu li a {
  color: #888;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 0 20px;
}

.sidebar-menu li a:hover {
  color: #2e37a4;
}

.sidebar-menu ul li .active img {
  filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(50%) contrast(340%);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.sidebar-menu li.active a {
  color: #2e37a4;
}

.menu-title {
  color: #333;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 20px;
}

.menu-title>i {
  float: right;
  line-height: 40px;
}

.sidebar-menu li.menu-title a {
  color: #009efb;
  display: inline-block;
  float: right;
  padding: 0;
}

.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  line-height: inherit;
  margin-bottom: 15px;
}

.sidebar-menu ul .submenu .active {
  color: #2e37a4;
  font-weight: 500;
}

.sidebar-menu ul li .active {
  color: #2e37a4;
  font-weight: 500;
}

.sidebar-menu ul .submenu ul .active {
  position: relative;
}

.sidebar-menu ul .submenu ul .active:before {
  background-image: url(../img/icons/right-icon.svg);
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  top: 50%;
  left: 35px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.sidebar-menu ul ul a.active {
  color: #2e37a4;
  font-weight: 500;
}

.mobile-user-menu {
  color: #fff;
  display: none;
  font-size: 24px;
  height: 70px;
  line-height: 70px;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 50px;
  z-index: 10;
}

.mobile-user-menu>a {
  color: #fff;
  padding: 0;
}

.mobile-user-menu>a:hover {
  color: #fff;
}

.mobile-user-menu>.dropdown-menu>a {
  line-height: 23px;
}

.profile-rightbar {
  display: none !important;
  color: #009efb;
  font-size: 26px;
  margin-left: 15px;
}

.fixed-sidebar-right {
  position: fixed;
  top: 60px;
  right: 0;
  width: 300px;
  margin-right: -300px;
  bottom: 0;
  z-index: 101;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.mobile_btn {
  display: none;
}

.sidebar .sidebar-menu>ul>li>a span {
  transition: all 0.2s ease-in-out 0s;
  margin-left: 8px;
  white-space: nowrap;
}

.sidebar .sidebar-menu>ul>li>a span.badge {
  color: #fff;
  margin-left: auto;
}

.sidebar-menu ul ul a {
  display: block;
  font-size: 14px;
  padding: 6px 5px 9px 34px;
  position: relative;
  color: rgba(46, 55, 164, 0.5);
  font-weight: 500;
}

.sidebar-menu ul ul {
  display: none;
}

.sidebar-menu ul ul ul a {
  padding-left: 70px;
}

.sidebar-menu ul ul ul ul a {
  padding-left: 90px;
}

.sidebar-menu>ul>li {
  position: relative;
}

.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 25px;
  display: inline-block;
  font-family: "FontAwesome";
  text-rendering: auto;
  line-height: 40px;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 23px;
}

.sidebar-menu .menu-arrow:before {
  content: "\f105";
}

.sidebar-menu li a.subdrop .menu-arrow {
  -ms-transform: rotate(90deg) !important;
  -webkit-transform: rotate(90deg) !important;
  -o-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
}

.sidebar-menu ul ul a .menu-arrow {
  top: 6px;
}

.sidebar-menu a {
  transition: unset;
  -moz-transition: unset;
  -o-transition: unset;
  -ms-transition: unset;
  -webkit-transition: unset;
}

.sidebar-menu>ul>li>a {
  padding: 12px 20px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  color: rgba(51, 53, 72, 0.75);
  font-weight: 500;
}

.sidebar-menu ul li a i {
  font-size: 18px;
  min-height: 24px;
  line-height: 18px;
  transition: all 0.2s ease-in-out 0s;
  background: rgba(46, 55, 164, 0.05);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.sidebar-menu ul li a span.menu-side {
  font-size: 18px;
  min-height: 24px;
  line-height: 18px;
  transition: all 0.2s ease-in-out 0s;
  background: rgba(46, 55, 164, 0.05);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-left: -9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.sidebar-menu ul .submenu .active img {
  filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(50%) contrast(340%);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.sidebar-menu li a:hover img {
  filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(50%) contrast(340%);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.sidebar-menu ul li.menu-title a i {
  font-size: 16px;
  margin-right: 0;
  text-align: right;
  width: auto;
}

.logout-btn {
  margin: 50px 0px;
}

.logout-btn a span.menu-side {
  font-size: 18px;
  min-height: 24px;
  line-height: 18px;
  transition: all 0.2s ease-in-out 0s;
  background: rgba(46, 55, 164, 0.05);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.logout-btn a {
  padding: 12px 20px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  color: #2e37a4;
  font-weight: 500;
}

.logout-btn a span {
  margin-left: 10px;
}

/*-----------------
	7. Content
-----------------------*/

.page-wrapper {
  left: 0;
  margin-left: 267px;
  padding-top: 70px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.page-wrapper>.content {
  padding: 30px;
  padding-bottom: 0;
}

/*-----------------
	12. Login
-----------------------*/

.login-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.login-wrapper .loginbox .login-right .login-right-wrap {
  max-width: 100%;
  flex: 0 0 100%;
}

.login-wrapper .loginbox .login-right .login-right-wrap .form-group {
  position: relative;
}

.login-wrapper .loginbox .login-right .login-right-wrap .form-group label {
  font-size: 12px;
  color: #333448;
  font-weight: 500;
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  margin-bottom: 0;
  padding: 0px 5px;
}

.login-danger,
.star-red {
  color: #ff0000;
}

.account-subtitle {
  color: rgba(51, 52, 72, 0.5);
  font-size: 14px;
  margin-bottom: 0;
  text-align: left;
  font-weight: 500;
}

.account-subtitle a {
  color: #2e37a4;
}

.login-wrapper .loginbox .login-right h2 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 40px;
}

.login-wrapper .loginbox .login-right .forgotpass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
}

.remember-me .custom_check {
  color: #333448;
  font-size: 13px;
  font-weight: 500;
}

.remember-me .custom_check .checkmark {
  width: 18px;
  height: 18px;
  top: 2px;
  left: 2px;
  border: 2px solid rgba(51, 52, 72, 0.2);
  border-radius: 4px;
  background-color: #fff;
}

.remember-me .custom_check .checkmark::after {
  left: 3px;
  top: 0px;
}

.login-wrapper .loginbox .login-right .forgotpass a {
  color: #00d3c7;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
}

.login-wrapper .loginbox .login-right .forgotpass a:hover {
  color: #333;
  text-decoration: underline;
}

.login-wrapper .loginbox .login-right .dont-have {
  color: #a0a0a0;
  margin: 20px 0px;
}

.login-wrapper .loginbox .login-right .dont-have a {
  color: #18aefa;
  font-weight: 500;
}

.login-wrapper .loginbox .login-right .dont-have a:hover {
  text-decoration: underline;
}

.social-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.social-login>span {
  color: #a0a0a0;
  margin-right: 8px;
}

.social-login>a {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 12px;
  height: 40px;
  margin-right: 6px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.social-login>a:hover {
  background-color: #e6e6e8;
  color: #fff;
}

.social-login>a:last-child {
  margin-right: 0;
}

.btn-primary.primary-reset {
  background-color: #18aefa;
  border-color: #18aefa;
}

.btn-primary.primary-reset:hover {
  background-color: #3d5ee1;
  border: 1px solid #3d5ee1;
}

.profile-views {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(51, 52, 72, 0.2);
  cursor: pointer;
}

.login-right .form-control {
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
  height: 45px;
}

.login-right .form-control:focus {
  border: 2px solid rgb(74 83 199 / 80%);
}

.btn-block {
  width: 100%;
}

.account-logo {
  margin-bottom: 40px;
  text-align: left;
}

/* .login-right .btn-primary {
  color: white!important;
  background: linear-gradient(92.39deg, #4e57cd 1.86%, #2e37a4 100%);
  border-radius: 12px;
  font-size: 16px;
  padding: 10px 15px;
}
.login-right .btn-primary:hover {
  background: linear-gradient(92.39deg, #5e66bf 1.86%, #8298c3 100%);
  border: 1px solid #00318b;
} */
.login-right .btn-primary {
  background: linear-gradient(92.39deg, #4e57cd 1.86%, #2e37a4 100%);
  border-radius: 12px;
  font-size: 16px;
  padding: 10px 15px;
}

.login-right .btn-primary:hover {
  background: linear-gradient(92.39deg, #2e37a4 1.86%, #00318b 100%);
  border: 1px solid #00318b;
}

.next-sign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.login-btn {
  margin-bottom: 40px;
}

.login-wrapper .login-right .user-lock-screen h2 {
  margin-bottom: 10px;
}

.user-lock-screen p {
  margin-bottom: 0;
  color: #333448;
  font-size: 14px;
  font-weight: 500;
}

.user-lock-screen {
  margin-bottom: 30px;
}

.sucess-mail h4 {
  font-size: 26px;
  font-weight: 600;
  margin: 15px 0px 20px;
}

.sucess-mail p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

/*-----------------
	14. Login
-----------------------*/

.login-sec {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: auto;
}

.log-img img {
  margin: 0 auto;
}

.login-wrapper {
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.login-wrap {
  background-color: #2e37a4;
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  padding: 0px;
  border-radius: 0px 65px 65px 0px;
}

.login-wrap-bg {
  position: absolute;
  right: 0;
  padding: 15px 90px 15px;
  height: 100%;
}

.login-wrap .login-sec {
  position: absolute;
  background-image: url("../img/login-0l.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover, auto;
  width: 100%;
}

.login-wrapper .loginbox {
  background: #ffffff;
  box-shadow: 0px 80px 80px rgba(46, 55, 164, 0.03);
  border-radius: 24px;
  display: flex;
  max-width: 830px;
  width: 100%;
}

.login-wrapper .loginbox .login-right {
  padding: 50px;
  width: 100%;
}

/*-----------------
	9. Dashboard
-----------------------*/

.dash-widget {
  background-color: #fff;
  border-radius: 12px;
  backdrop-filter: blur(2px);
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}

.dash-boxs {
  background: rgba(46, 55, 164, 0.05);
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 12px;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.dash-content h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.dash-content {
  color: #2e37a4;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.dashboard-count {
  color: #2e37a4;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
}

.dash-content p {
  color: rgba(51, 52, 72, 0.5);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

.dash-content p .negative-view {
  color: #ff3667;
}

.dash-content p .passive-view {
  color: #00d3c7;
}

.comman-flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.card-table.card-table-top .card-body {
  height: 175px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.chart-title {
  margin-bottom: 25px;
}

.chart-title h4 {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  color: #333;
}

.chart-title span {
  color: #009efb;
}

.card-title>a.btn {
  color: #fff;
}

ul.chat-user-total li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333448;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
}

ul.chat-user-total li i.current-users {
  color: #2e37a4;
  padding-right: 5px;
  font-size: 8px;
}

ul.chat-user-total li i.low-users {
  color: #d5d7ed;
  padding-right: 5px;
  font-size: 8px;
}

ul.chat-user-total li i.old-users {
  color: #00d3c7;
  padding-right: 5px;
  font-size: 8px;
}

.new-patient-table {
  display: table;
}

.new-patient-table td,
.new-patient-table th {
  vertical-align: middle;
}

.new-patient-table tr td img {
  margin-right: 5px;
}

.btn-primary-one {
  border: 1px solid #ffbc35;
  border-radius: 25px;
  width: 100px;
  background: transparent;
  color: #000;
}

.btn-primary-two {
  border: 1px solid #009efb;
  border-radius: 25px;
  width: 100px;
  background: transparent;
  color: #000;
}

.btn-primary-three {
  border: 1px solid #55ce63;
  border-radius: 25px;
  width: 100px;
  background: transparent;
  color: #000;
}

.btn-primary-four {
  border: 1px solid #ffbc35;
  border-radius: 25px;
  width: 100px;
  background: transparent;
  color: #000;
}

.bar-chart {
  position: relative;
  width: 100%;
  margin-top: 15px;
}

.bar-chart>.legend {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  margin-bottom: -45px;
  border-top: 1px solid #000;
}

.bar-chart>.legend>.item {
  position: relative;
  display: inline-block;
  float: left;
  width: 25%;
}

.bar-chart>.legend>.item:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 1px;
  height: 8px;
  background-color: #000;
  margin-top: -8px;
}

.bar-chart>.legend>.item.text-end:before {
  right: 0;
  left: auto;
}

.bar-chart>.legend>.item h4 {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 5px;
}

.bar-chart>.chart {
  position: relative;
  width: 100%;
}

.bar-chart>.chart>.item {
  position: relative;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
}

.bar-chart>.chart>.item>.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffbc35;
  z-index: 5;
}

.bar-chart>.chart>.item>.bar>.percent {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  line-height: 40px;
  padding-right: 12px;
  z-index: 15;
}

.bar-chart>.chart>.item>.bar>.item-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #009efb;
  z-index: 10;
}

.bar-chart>.chart>.item>.bar>.item-progress>.title {
  display: block;
  position: absolute;
  height: 40px;
  line-height: 40px;
  padding-left: 12px;
  letter-spacing: 2px;
  z-index: 15;
}

.hospital-barchart h4 {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  color: #333;
}

.custom-table tr {
  background-color: #fff;
  white-space: nowrap;
}

.table.custom-table>tbody>tr>td,
.table.custom-table>tbody>tr>th,
.table.custom-table>tfoot>tr>td,
.table.custom-table>tfoot>tr>th,
.table.custom-table>thead>tr>td,
.table.custom-table>thead>tr>th {
  padding: 10px 15px;
  vertical-align: middle;
}

.member-panel .contact-list>li:last-child {
  border-bottom: 0;
}

.member-panel .card-body {
  overflow-y: auto;
  height: 245px;
  -webkit-overflow-scrolling: touch;
  padding: 0;
}

.contact-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.contact-list>li {
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  padding: 10px;
  background-color: #fff;
}

.contact-cont {
  position: relative;
}

.contact-info {
  padding: 0 30px 0 50px;
}

.contact-date {
  color: #888;
  font-size: 12px;
}

.take-btn {
  min-width: 80px;
}

.good-morning-blk {
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 25px;
  position: relative;
  z-index: 9;
}

.good-morning-blk:before {
  content: "";
  position: absolute;
  background-image: url(../img/bg-img-01.png);
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.morning-user {
  padding: 50px 0px 50px 35px;
}

.morning-user h2 {
  font-size: 28px;
  font-weight: 600;
}

.morning-user h2 span {
  color: #2e37a4;
}

.morning-user p {
  font-size: 15px;
  font-weight: 500;
  color: rgba(51, 52, 72, 0.5);
  margin-bottom: 0;
}

.morning-img img {
  position: absolute;
  bottom: 0;
}

.position-blk {
  position: relative;
}

.activity-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.activity-boxs {
  background: rgba(46, 55, 164, 0.05);
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 50px;
}

.departments-list h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.departments-list p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(51, 53, 72, 0.5);
  margin-bottom: 0;
}

.chart-user-icon {
  position: relative;
}

.chart-user-icon img {
  position: absolute;
  top: 65px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 15px;
}

.custom-table tbody tr {
  box-shadow: 0 0 3px #e5e5e5;
  border: 0;
}

.custom-table tbody tr td,
.custom-table thead tr th {
  border: 0;
}

.custom-table tbody tr td {
  font-size: 14px;
}

.custom-table thead tr th {
  font-size: 14px;
  color: rgba(51, 52, 72, 0.5);
}

.table-dash .dataTables_length,
.table-dash .dataTables_info,
.table-dash .pagination {
  display: none;
}

.table-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.table-image img {
  margin-right: 10px;
}

.patient-views {
  color: #2e37a4;
  font-size: 14px;
  font-weight: 500;
}

.appoint-doctor h2 {
  color: #2e37a4;
}

.appoint-time span {
  color: rgba(51, 52, 72, 0.5);
  font-size: 12px;
  font-weight: 500;
}

.appoint-time h6 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.patient-visit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.doctor-list-blk {
  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 25px;
}

.doctor-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.doctor-box-icon {
  width: 66px;
  height: 66px;
  background: #2e37a4;
  border-radius: 12px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.doctor-content {
  width: 100%;
}

.doctor-content h4 {
  font-size: 24px;
  color: #2e37a4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.doctor-content h5 {
  font-size: 14px;
  margin-bottom: 0;
}

.doctor-content h4 span:nth-child(2) {
  font-size: 14px;
  color: rgb(51 52 72 / 20%);
}

.doctor-content h4 span:last-child {
  font-size: 10px;
  border-radius: 20px;
  margin: auto;
  padding: 5px;
}

.border-right-bg:before {
  content: "";
  position: absolute;
  background: rgba(46, 55, 164, 0.2);
  height: 44px;
  width: 2px;
  right: 0;
}

.income-value {
  text-align: center;
}

.income-value h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.income-value h3 span {
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 52, 72, 0.5);
}

.income-value p {
  font-size: 12px;
  font-weight: 500;
  color: rgba(51, 52, 72, 0.5);
  margin-bottom: 0;
}

.income-value p span {
  color: #00d3c7;
}

.patient-structure h5 {
  font-size: 16px;
  font-weight: 500;
  color: #2e37a4;
  margin-bottom: 10px;
}

.struct-point {
  width: 100%;
}

.patient-structure h3 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.patient-structure h3 span {
  font-size: 12px;
  padding: 3px 7px;
  border-radius: 20px;
}

.check-point {
  border: 0;
  border-radius: 4px;
}

.card.wallet-widget {
  margin-bottom: 25px;
  padding: 15px;
  width: 100%;
}

.main-limit {
  text-align: center;
}

.main-limit p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.main-limit h4 {
  font-size: 24px;
  font-weight: 600;
  color: #2e37a4;
  margin-bottom: 0;
}

.wallet-widget .circle-bar {
  text-align: center;
}

.wallet-widget .circle-bar>div {
  display: inline-block;
  position: relative;
  text-align: center;
}

.wallet-widget .circle-bar>div b {
  left: 0;
  position: absolute;
  top: 50%;
  right: 0;
  text-align: center;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background: rgba(0, 211, 199, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.wallet-widget .circle-bar>div canvas {
  width: 80px;
  height: 80px;
}

.teaching-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.steps-history {
  margin-bottom: 0;
  margin-right: 10px;
  align-items: baseline;
  display: inline-grid;
  padding: 0;
}

.steps-history li {
  margin-bottom: 10px;
  color: rgba(51, 53, 72, 0.5);
  font-weight: 500;
  list-style: none;
  font-size: 12px;
}

.activity-feed {
  list-style: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
  width: 100%;
}

.activity-feed .feed-item {
  border-left: 2px solid #e4e8eb;
  padding-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.activity-feed .feed-item:last-child {
  border-color: transparent;
  padding-bottom: 0;
}

.activity-feed .feed-item:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #333448;
  width: 7px;
  height: 7px;
  top: 0px;
  left: -4px;
  border-radius: 50px;
}

.doctor-date-list {
  padding: 0;
}

.doctor-date-list li {
  list-style: none;
  color: #333548;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

.doctor-date-list li span {
  color: rgba(51, 53, 72, 0.5);
  font-size: 12px;
  font-weight: 500;
  padding-left: 15px;
}

.doctor-date-list li i {
  font-size: 6px;
}

.dolor-activity {
  width: 100%;
}

.dolor-activity .stick-line {
  text-decoration: line-through;
  color: rgba(51, 53, 72, 0.5);
}

.active-doctor {
  border: 1px solid rgba(46, 55, 164, 0.2);
  box-shadow: 0px 25px 50px rgba(46, 55, 164, 0.07);
  border-radius: 14px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.doctor-sub-list {
  padding: 10px;
  background: #ffffff;
  border: 1px solid rgba(46, 55, 164, 0.2);
  box-shadow: 0px 25px 50px rgba(46, 55, 164, 0.07);
  border-radius: 14px;
  width: 100%;
  position: absolute;
  z-index: 99;
  display: none;
}

.ongoing-blk {
  position: relative;
}

.schedule-time li {
  width: 30px;
  height: 30px;
  border: 1px solid rgba(46, 55, 164, 0.2);
  border-radius: 5px;
}

.schedule-time li {
  margin-right: 5px;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.schedule-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-top: 1px solid rgba(51, 52, 72, 0.2);
  padding-top: 10px;
}

.appoint-start {
  font-size: 12px;
  border-radius: 10px;
  padding: 10px 20px;
}

.new-dot {
  padding: 3px 10px;
  font-size: 12px;
  margin-left: auto;
  border-radius: 8px;
}

.doctor-date-list .new-dot i {
  font-size: 5px;
}

.patient-new-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.average-health h5 {
  margin-bottom: 0;
  background: rgba(46, 55, 164, 0.05);
  border-radius: 8px;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 500;
}

.average-health h5 span {
  color: rgba(46, 55, 164, 0.5);
  font-size: 10px;
}

.dash-content h5 {
  font-size: 24px;
  color: #2e37a4;
  font-weight: 600;
  margin-bottom: 20px;
}

.calendar header .month {
  font-size: 18px;
}

.dash-content h5 span {
  font-size: 16px;
  color: rgba(51, 52, 72, 0.5);
}

.report-blk {
  width: 100%;
}

.ongoing-drapt {
  position: relative;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ongoing-drapt i {
  background: #ffffff;
  border: 1px solid rgba(46, 55, 164, 0.2);
  box-shadow: 0px 25px 50px rgba(46, 55, 164, 0.07);
  border-radius: 6px;
  color: #2e37a4;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.doctor-date-list li .ongoing-drapt i {
  font-size: 12px;
}

.active-doctor.dropdown-toggle::after {
  display: none;
}

.doctor-sub-list .dropdown-item {
  padding: 0;
}

.doctor-sub-list .dropdown-item:focus,
.doctor-sub-list .dropdown-item:hover {
  background-color: transparent;
}

.active-doctor .active-circles {
  color: #00d3c7;
}

.doctor-date-list .active-doctor span {
  color: #2e37a4;
}

.doctor-date-list .active-doctor span.ongoing-drapt {
  color: rgba(51, 52, 72, 0.5);
}

.note-checkit .custom_check .checkmark {
  border: 2px solid rgba(46, 55, 164, 0.2);
  border-radius: 5px;
}

.note-checkit .custom_check input:checked~.checkmark {
  background-color: #2e37a4;
  border-color: #2e37a4;
}

.note-checkit .custom_check {
  color: #333548;
  font-size: 12px;
  font-weight: 600;
}

.wallet-widget.general-health {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -ms-flex-pack: space-around;
  flex-direction: inherit;
}

.general-health .main-limit p {
  font-weight: 600;
}

.general-health.wallet-widget .circle-bar>div canvas {
  width: 135px;
  height: 135px;
}

.status-gray.re-shedule {
  color: #2e37a4;
  font-weight: 600;
  font-size: 12px;
}

.patient-table tbody tr td,
.patient-table tbody tr td h2 {
  font-weight: 500;
  color: #333448;
}

.patient-table tbody tr td:nth-child(3) {
  color: rgba(51, 52, 72, 0.5);
}

.treat-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.user-imgs-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-imgs-blk img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50px;
  margin-right: 20px;
  border: 2px solid rgba(46, 55, 164, 0.2);
}

.active-user-detail h4 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.active-user-detail p {
  font-size: 12px;
  font-weight: 500;
  color: rgba(51, 52, 72, 0.5);
  margin-bottom: 0;
}

.weight-blk .slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.2;
}

.weight-blk .slick-slide div {
  text-align: center;
}

.weight-blk .slick-slide span {
  opacity: 0;
}

.weight-blk .slick-slide h4 {
  margin-bottom: 0;
  font-size: 14px;
}

.weight-blk .slick-current span {
  opacity: 1;
  font-size: 12px;
}

.weight-blk .slick-active {
  opacity: 0.5;
}

.weight-blk .slick-current {
  opacity: 1;
}

.max-blk {
  width: 50%;
}

.weight-blk {
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 14px;
  padding: 25px 0px 20px;
  position: relative;
}

.report-head h4 {
  margin-bottom: 0;
  font-size: 18px;
}

.report-head img {
  width: 40px;
  height: 40px;
}

.weight-blk:before {
  background-image: url(../img/icons/mass-icon.svg);
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  left: 50%;
  top: 0px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.weight-bar.progress {
  height: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 40px 0px 10px;
}

.weight-bar .progress-bar {
  width: 100px;
  background-color: #2e37a4;
  border-radius: 20px;
}

.weight-checkit {
  list-style: none;
  padding: 0;
  color: rgba(51, 52, 72, 0.5);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.pulse {
  display: block;
  position: absolute;
  top: 24px;
  right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00d3c7;
}

.pulse:before {
  content: "";
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background: rgb(0 211 199 / 50%);
  cursor: pointer;
  box-shadow: 0 0 0 rgb(0 211 199);
  animation: pulse 1s linear infinite;
  z-index: 1;
}

@keyframes pulse {
  100% {
    transform: scale(2.5);
  }
}

/*-----------------
	10. Activity
-----------------------*/

.activity-box {
  position: relative;
}

.activity-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.activity .activity-list>li .activity-user {
  height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  left: 8px;
  width: 32px;
}

.activity .activity-list>li .activity-content {
  margin: 0 0 0 40px;
  padding: 0;
  position: relative;
}

.activity-list>li {
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
}

.activity-list>li:last-child .activity-content {
  margin-bottom: 0;
}

.activity-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px;
}

.activity-list>li .activity-user {
  background: #fff;
  height: 32px;
  left: -7px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 3px;
  width: 32px;
}

.activity-list>li .activity-content {
  background-color: #fff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}

.activity-list>li .time {
  color: rgba(51, 52, 72, 0.5);
  display: block;
  font-size: 12px;
  line-height: 1.35;
}

.activity-delete {
  background-color: #ffe5e6;
  border: 1px solid #fe0000;
  color: #fe0000;
  display: none;
  font-size: 20px;
  height: 20px;
  line-height: 18px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 20px;
}

.activity-delete:hover {
  background-color: #fe0000;
  color: #fff;
}

.activity-list>li:hover .activity-delete {
  display: inline-block;
}

.activity-list::before {
  background: rgba(46, 55, 164, 0.1);
  bottom: 0;
  content: "";
  left: 23px;
  position: absolute;
  top: 8px;
  width: 2px;
}

.timeline-group {
  margin-right: 20px;
  min-width: 100px;
}

.timeline-group h4 {
  color: #333448;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.timeline-group-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.comman-activitys {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 0px 16px 16px 16px;
  padding: 20px;
  width: 100%;
}

.comman-activitys h3 {
  color: #2e37a4;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.comman-activitys h3 span {
  color: #333448;
  font-size: 14px;
  font-weight: 500;
}

.comman-activitys p {
  color: rgba(51, 52, 72, 0.5);
  margin-bottom: 0px;
  font-size: 12px;
}

.activity-sub-list li {
  margin-right: 10px;
}

.activity-sub-list li img {
  border-radius: 8px;
}

.activity-maps {
  border-radius: 8px;
  border: 2px solid #fff;
}

.activity-maps iframe {
  width: 100%;
}

/*-----------------
	11. Select2
-----------------------*/

.select2-container .select2-selection--single {
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
  height: 40px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
  right: 7px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  border-top: 0;
  border-left: 0;
  border-bottom: 1px solid #171717;
  border-right: 1px solid #171717;
  content: "";
  height: 6px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 6px;
  vertical-align: 0px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  border-top: 0;
  border-left: 0;
  border-bottom: 1px solid #171717;
  border-right: 1px solid #171717;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #2e37a4;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #eaeaea;
  border-radius: 0;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #eaeaea;
  border-radius: 0;
}

.select2-container .select2-selection--multiple {
  min-height: 40px;
}

.select2-results__option {
  font-size: 14px;
}

/*-----------------
	12. Nav tabs
-----------------------*/

.nav-tabs>li>a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}

.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus {
  border-color: transparent;
  color: #333;
}

.nav-tabs.nav-justified>li>a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs.nav-justified.nav-tabs-solid>li>a {
  border-color: transparent;
}

.nav-tabs.nav-tabs-solid>li>a {
  color: #333;
}

.nav-tabs.nav-tabs-solid>li>a.active,
.nav-tabs.nav-tabs-solid>li>a.active:hover,
.nav-tabs.nav-tabs-solid>li>a.active:focus {
  background-color: #2e37a4;
  border-color: #2e37a4;
  color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:focus {
  border-radius: 50px;
}

.nav-tabs-justified>li>a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs-justified>li>a:hover,
.nav-tabs-justified>li>a:focus {
  border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid>li>a {
  border-color: transparent;
}

/*-----------------
	13. Holidays
-----------------------*/

.custom-table tr.holiday-completed {
  color: #aaa;
}

.modal-header.bg-danger .modal-title {
  color: #fff;
}

.dropdown.action-label {
  display: inline-block;
}

.action-label>a {
  display: inline-block;
  min-width: 103px;
}

.action-label .btn-sm {
  padding: 5px;
}

/*-----------------
	14. Edit Profile
-----------------------*/

.profile-img-wrap {
  height: 120px;
  position: absolute;
  width: 120px;
  background: #fff;
  overflow: hidden;
}

.profile-img-wrap-sm {
  height: 75px;
  position: absolute;
  width: 75px;
  background: #fff;
  overflow: hidden;
}

.edit-staff-profile .profile-img-wrap {
  height: 150px;
  position: relative;
  width: 150px;
  margin: auto;
  background: #fff;
  overflow: hidden;
}

.profile-basic {
  margin-left: 140px;
}

/* .profile-img-wrap img {
  width: 120px;
  height: 120px;
} */

.fileupload.btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.5);
  border-radius: 0;
  padding: 3px 10px;
  border: none;
}

.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: -3px;
  top: -3px;
  padding: 5px;
}

.btn-text {
  color: #fff;
}

/*-----------------
	15. Chat
-----------------------*/

.media .avatar {
  min-width: 36px;
}

.avatar {
  background-color: #aaa;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 28px;
  line-height: 38px;
  margin: 0 10px 0 0;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 28px;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
}

.avatar:hover {
  color: #fff;
}

.avatar>img {
  width: 100%;
  display: block;
}

.card.chat-box {
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}

.card.chat-box-clinic {
  padding: 0;
  border-radius: 8px;
  width: 100%;
}

.chat-box-clinic .chat-user-group,
.chat-box-clinic .chat-search {
  padding: 10px 20px;
}

.chat-box-clinic .chat-user-group,
.chat-box-clinic .chat-search {
  margin-bottom: 20px;
}

.chat-box-clinic .chat-user-group:hover {
  background: #2e37a4;
}

.chat-box-clinic .chat-user-group:hover .user-titles h5,
.chat-box-clinic .chat-user-group:hover .chat-user-time p {
  color: #ffffff;
}

.chat-box-clinic .chat-user-group:hover .user-text p {
  color: rgba(255, 255, 255, 0.5);
}

.chat-user-group:hover .chat-user-count span {
  background: #fff;
  color: #2e37a4;
}

.chat-user-group {
  margin-bottom: 30px;
}

.chat-user-group-head .img-users {
  margin-right: 10px;
}

.chat-user-group-head {
  padding: 20px;
}

.chat-user-group-head .user-titles h5 {
  color: #2e37a4;
}

.chat-users {
  width: 100%;
}

.chat-users.user-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.user-dot-list {
  width: 40px;
  height: 40px;
  background: rgba(51, 52, 72, 0.05);
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.user-dot-list a {
  color: #171717;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-user-time,
.chat-user-count {
  margin-left: auto;
}

.user-titles h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}

.chat-user-time p,
.user-text p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  color: rgba(51, 53, 72, 0.5);
}

.chat-user-count span {
  background: #2e37a4;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  font-size: 10px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.chat-user-count .user-check {
  background: none;
  color: #838383;
  font-size: 14px;
}

.chat-user-group .img-users {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.user-check.active {
  color: #399e8d;
}

.chat-user-group .active-users {
  width: 8px;
  height: 8px;
  right: 5px;
  top: 0px;
}

.img-users {
  position: relative;
}

.active-users {
  width: 10px;
  height: 10px;
  background: green;
  border-radius: 50px;
  position: absolute;
  right: 0;
  border: 1px solid #ffffff;
}

.call-user img {
  width: 45px;
  height: 45px;
  border-radius: 50px;
}

.call-list-add li a {
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #333448;
  font-size: 14px;
  font-weight: 600;
}

.call-list-add li {
  list-style: none;
  margin-right: 10px;
}

.call-list-add li:first-child a {
  width: 67px;
}

.call-list-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}

.chat-search .form-group {
  position: relative;
  width: 100%;
}

.chat-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 30px;
}

.add-search a {
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #2e37a4;
}

.add-search a:hover {
  background: #223d9f;
}

.add-search a:hover i {
  color: #fff;
}

.top-liv-search.top-chat-search .btn {
  left: 0;
  right: inherit;
}

.top-liv-search.top-chat-search .form-control {
  padding-left: 40px;
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  border: 0;
}

.top-liv-search.top-chat-search .form-control::placeholder {
  color: rgba(46, 55, 164, 0.3);
  font-size: 12px;
}

.chat-search-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.chat-search-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin-bottom: 0;
}

.chat-search-list ul li {
  padding: 10px 0px;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 0px 10px;
  background: rgba(46, 55, 164, 0.05);
  border-radius: 8px;
}

.chat-search-list ul li:last-child {
  margin-right: 0;
}

.card.chat-message-box {
  padding: 20px;
}

.message-sub-box h4 {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

.message-sub-box p {
  font-size: 12px;
  font-weight: 400;
  background: rgba(51, 52, 72, 0.1);
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  margin-bottom: 5px;
  max-width: 270px;
}

.message-sub-box span {
  color: rgba(51, 53, 72, 0.5);
  font-size: 10px;
  font-weight: 500;
}

.media.sent .media-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.media.sent .message-sub-box {
  text-align: right;
}

.media.sent .message-sub-box p {
  background: rgba(46, 55, 164, 0.1);
  border-radius: 0px 15px 15px 15px;
  color: #2e37a4;
}

.msg-sub-list {
  margin-bottom: 0;
}

.msg-sub-list li {
  background: rgba(46, 55, 164, 0.05);
  border-radius: 8px;
  margin-bottom: 5px;
  padding: 5px;
  max-width: 270px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

.msg-sub-list li:last-child {
  margin-bottom: 0;
}

.footer-discussion {
  display: inline-flex;
  width: 100%;
}

.footer-discussion .inputgroups {
  width: 100%;
  margin-top: 30px;
  position: relative;
}

.inputgroups input[type="text"] {
  width: 100%;
  height: 52px;
  padding-left: 32px;
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  border: 0;
}

.inputgroups input[type="text"]::placeholder {
  font-size: 12px;
  color: rgba(51, 52, 72, 0.3);
}

.chat-footer-box {
  border-top: 2px solid rgba(46, 55, 164, 0.05);
  border-radius: 0px 0px 10px 10px;
}

.position-icon {
  position: absolute;
}

.micro-text {
  top: 15px;
  left: 10px;
}

.send-chat {
  background: #234ce3;
  border-radius: 8px;
  width: 52px;
  height: 52px;
  top: 0;
  right: 0;
}

.comman-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.symple-text ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}

.symple-text ul li {
  list-style: none;
}

.symple-text {
  top: 15px;
  right: 65px;
}

.chat-heading h3 {
  margin-bottom: 20px;
}

.chat-users.chat-user-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.comman-space-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.chat-user-blk .user-titles p {
  color: rgba(51, 53, 72, 0.5);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.chat-user-blk .user-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-user-icon {
  margin-left: 15px;
}

.call-all {
  margin-bottom: 25px;
}

.call-all h4 {
  color: #333448;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.call-all h4 span {
  background: #0dbf0a;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 10px;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.call-all h5 {
  margin-bottom: 0;
}

.call-all h5 a {
  color: #2e37a4;
  font-size: 14px;
  font-weight: 600;
}

.voice-call-user {
  margin-bottom: 30px;
}

.voice-call-user img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  margin-bottom: 10px;
}

.voice-call-user h3 {
  color: #333448;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.voice-call-user p {
  color: rgba(51, 53, 72, 0.5);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.voice-time {
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  max-width: 191px;
  margin: auto;
}

.voice-time h3 {
  font-size: 26px;
  color: #2e37a4;
  font-weight: 600;
  margin-bottom: 0;
  padding: 10px;
}

.voice-menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 30px 0px;
  padding: 0;
}

.voice-menu ul li {
  margin-right: 20px;
  list-style: none;
}

.voice-menu ul li:last-child {
  margin-right: 0px;
}

.voice-menu ul li:hover a {
  background: #2e37a4;
  border: 1px solid #2e37a4;
}

.voice-menu ul li:hover img {
  filter: invert(1) brightness(100);
}

.voice-menu ul li a {
  width: 50px;
  height: 50px;
  background: rgba(51, 52, 72, 0.05);
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.end-call-voice .btn-primary {
  background: #fe3f51;
  border-radius: 8px;
  min-width: 136px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
}

.end-call-voice .btn-primary:hover {
  background: #234ce3;
  border: 1px solid #234ce3;
}

.video-call-blk.chat-user-group:hover {
  border-radius: 8px;
  background: rgba(51, 52, 72, 0.05);
  border-radius: 8px;
}

.video-call-blk.chat-user-group {
  padding: 10px;
  margin-bottom: 15px;
}

.chat-user-group .user-titles h5 {
  color: #333448;
}

.message-sub-box h5 {
  color: rgba(51, 52, 72, 0.5);
  font-size: 10px;
  font-weight: 500;
}

.video-chat-show .message-sub-box p {
  font-size: 12px;
}

.video-chat-show ul li {
  margin-bottom: 15px;
}

.video-chat-show ul {
  margin-bottom: 0px;
  padding: 0;
}

.video-chat-show ul li:last-child {
  margin-bottom: 0px;
}

.calling-income h4 {
  color: #35446f;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
}

.crypto-income-blk .voice-call-user h3 {
  color: #35446f;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.crypto-income-blk .voice-call-user p {
  color: #2e37a4;
  font-size: 14px;
  font-weight: 600;
}

.voice-menu-income .btn-primary {
  width: 60px;
  height: 60px;
  border-radius: 12px;
}

.voice-menu-income .call-remove {
  background: #fe3f51;
}

.voice-menu-income .call-received {
  background: #0dbf0a;
}

.top-liv-search.top-chat-search .btn {
  left: 0;
  right: inherit;
}

.top-liv-search .btn {
  background-color: transparent;
  border-color: transparent;
  color: rgba(17, 17, 17, 0.32);
  min-height: 45px;
  padding: 7px 15px;
  position: absolute;
  right: 0;
  top: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/*-----------------
	10. Join Call
-----------------------*/

.page-content {
  width: 100%;
}

.join-call {
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
}

.join-video {
  position: relative;
  margin-bottom: 30px;
}

.join-video img {
  border-radius: 10px;
  object-fit: cover;
}

.join-video.video-hide>img,
.video-wrap.video-hide>video {
  display: none;
}

.video-avatar {
  color: #fff;
  background: #5d5d5d;
  font-size: 50px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.video-avatar.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.video-avatar h4 {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

.join-content {
  padding-bottom: 25px;
}

.join-content h3 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 37px;
}

.join-content label {
  font-size: 20px;
  margin-bottom: 1.25rem;
}

.icon-mic {
  background: #31aa52;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}

.icon-mic i {
  color: #fff;
}

.btn-join {
  font-weight: 700;
  font-size: 20px;
  padding: 14px 20px;
  min-width: 192px;
  border-radius: 10px;
}

.btn-infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-infos .btn-join {
  margin-right: 18px;
}

.btn-infos .icon-mic {
  margin-right: 15px;
}

.btn-infos span {
  font-size: 18px;
  color: #808184;
}

.call-icons {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.call-icons .call-items {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%, 0);
}

.call-icons .call-items .call-item {
  display: inline-block;
  text-align: center;
  margin-right: 15px;
}

.call-icons .call-items .call-item:last-child {
  margin-right: 0;
}

.call-icons .call-items .call-item a {
  color: #fff;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.07);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 60px;
  height: 60px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 20px;
  white-space: nowrap;
}

.call-icons .call-items .call-item a:hover {
  opacity: 0.8;
}

.call-icons .call-items .call-item .mute-video.stop,
.call-icons .call-items .call-item .mute-bt.stop {
  background: #eb4132;
  border: 1px solid #eb4132;
}

.set-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
}

.vert-icon,
a.vert-icon {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px;
  color: #000;
}

.voice-sec1 {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}

.voice-sec {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
}

.voice-icon,
a.voice-icon {
  width: 40px;
  height: 40px;
  background: #31aa52;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #fff;
}

.voice-icon:hover,
a.voice-icon:hover,
.vert-icon:hover,
a.vert-icon:hover {
  opacity: 0.8;
}

/*-----------------
	Meeting Call
-----------------------*/

.meet-icons {
  padding: 40px 0px 20px;
  margin-top: 40px;
  border-top: 2px solid rgba(46, 55, 164, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
}

.meet-icons .meet-items,
.action-items {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.meet-icons .meet-items .meet-item,
.action-items .action-item {
  display: inline-block;
  text-align: center;
  margin-right: 15px;
}

.meet-icons .meet-items .meet-item:last-child,
.action-items .action-item:last-child {
  margin-right: 0;
}

.meet-icons .meet-items .meet-item>a {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 12px;
  color: #636e8e;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 20px;
}

.meet-icons .meet-items .meet-item>a:hover img {
  filter: invert(1) brightness(100);
}

.meet-icons .meet-items .meet-item>a:hover,
.action-items .action-item a:hover {
  opacity: 0.8;
  background: #2e37a4;
}

.meet-icons .meet-items .meet-item>a.showChatList.active,
.meet-icons .meet-items .meet-item>a.showInviteList.active,
.meet-icons .meet-items .meet-item>a.hand-raise.active,
.action-items .action-item a.record-icon.active {
  background: #2e37a4;
}

.meet-icons .meet-items .meet-item>a.mute-bt.stop,
.meet-icons .meet-items .meet-item>a.mute-video.stop {
  background: #2e37a4;
}

.meet-icons .meet-items .meet-item>a.mute-video.stop .feather-video-off {
  filter: invert(1) brightness(100);
}

.meet-icons .meet-items .meet-item>a.show {
  background: #0074e0;
}

.action-items .action-item a {
  width: 80px;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 20px;
  box-shadow: 0px 15px 15px rgba(229, 229, 229, 0.32);
  border-radius: 10px;
  color: #fff;
}

.action-items .action-item a.share-icon {
  background: #eb4132;
}

.action-items .action-item a.callend-icon {
  background: #0074e0;
}

.action-items .action-item a.record-icon {
  background: #31aa52;
}

.end-calls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.action-icons {
  text-align: center;
}

.part-name {
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: left;
}

.part-name h4 {
  font-weight: 600;
  font-size: 10px;
  color: #fff;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #35446f;
  opacity: 0.75;
  backdrop-filter: blur(2.5px);
  border-radius: 8px;
  padding: 10px;
  display: inline-block;
}

.more-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.more-icon a {
  color: #fff;
  background: #35446f;
  opacity: 0.75;
  border-radius: 8px;
  width: 38px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.single-user .more-icon a {
  width: 30px;
  height: 30px;
}

.more-icon a:first-child {
  padding-left: 0;
}

.more-icon a:hover {
  opacity: 0.8;
}

.meeting .part-name {
  top: inherit;
  left: 15px;
  bottom: 15px;
}

.meeting .more-icon {
  top: 15px;
  right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.meeting-wrapper,
.meeting {
  width: inherit;
  max-width: inherit;
}

.show-chat-list,
.show-invite-list,
.show-chat-list .user-sidebar-right,
.show-invite-list .invite-sidebar-right {
  transition: all 0.8s ease;
}

.user-sidebar-right,
.invite-sidebar-right {
  background: #dedede;
  border-radius: 10px;
  width: 410px;
  position: absolute;
  top: 105px;
  bottom: 117px;
  right: 0;
  margin-right: -410px;
  transition: all 0.8s ease;
  overflow: hidden;
  font-size: 13px;
  z-index: 3;
}

.join-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  overflow: hidden;
}

.join-contents.grid-view {
  align-content: center;
}

.ripple {
  width: 114px;
  height: 114px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.multigrid-view .text-box {
  height: 61px;
  width: 61px;
}

.multigrid-view .ripple {
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.meeting .join-video {
  margin: 0 5px 10px;
  width: 100%;
  height: 309px;
}

.join-video.user-active {
  background: #415b6a;
  border-radius: 10px;
}

.join-video.single-user {
  width: 23%;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0 5px 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
  height: auto;
  background: #415b6a;
  border-radius: 10px;
  padding: 0;
}

.multigrid-view .join-video.single-user {
  width: calc(20% - 10px);
  flex-grow: 0;
}

.join-video.single-user img {
  height: auto;
}

.text-avatar h4 {
  color: #fff;
}

.text-avatar {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-box {
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 36px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.settings-menu {
  background: #e4eeff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  padding: 0 15px;
}

.settings-menu li a {
  padding: 12px 0;
  border-bottom: 1px solid #ffffff;
  width: auto;
  height: auto;
  background: #e4eeff;
  font-weight: 600;
  font-size: 14px;
  color: #000;
}

.settings-menu li a:focus,
.settings-menu li a:hover {
  color: #000;
  background-color: #e4eeff;
}

.settings-menu li:last-child a {
  border: 0;
}

.settings-menu .user-img {
  font-size: 18px;
}

.settings-menu .user-img img {
  width: 36px;
  border-radius: 10px;
}

.settings-menu.setting-list-menu li a {
  background: transparent;
}

.overlay-icon {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 42px;
  left: 15px;
  opacity: 0;
  transition: opacity 0.3s linear;
}

.overlay-icon a {
  color: #fff;
  padding: 3px 0;
  font-size: 14px;
  text-align: center;
}

.overlay-icon a:hover {
  opacity: 0.8;
}

.join-video.single-user::before,
.meeting .join-video::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  content: "";
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s linear;
}

.join-video.single-user:hover::before,
.meeting .join-video:hover::before {
  opacity: 1;
}

.join-video.single-user:hover .overlay-icon,
.join-video:hover .overlay-icon {
  opacity: 1;
}

.more-icon.mic-view .mic-off {
  display: block;
}

.grid-view .join-video.single-user {
  width: 48%;
}

.join-contents.multigrid-view {
  max-width: 100%;
  padding: 25px 100px 0;
  justify-content: center;
}

.meeting-list {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.user-active .more-icon .handraise-on.active {
  display: block;
  animation: mymove 5s;
}

.user-active .more-icon .handraise-on.active .meeting .join-video.user-active::before {
  animation: mymove 5s infinite;
  opacity: 1;
}

@keyframes mymove {
  from {
    color: #0074e0;
  }

  to {
    color: transparent;
  }
}

.join-contents.vertical-view {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-content: center;
  max-width: 1300px;
}

.join-contents.vertical-view .join-video.user-active {
  flex: 1;
  margin-bottom: 5px;
}

.join-contents.vertical {
  flex-direction: column;
  height: 200px;
}

.join-contents.vertical-view .join-video.single-user {
  width: 97%;
  flex-grow: 1;
}

.meeting .join-contents.vertical .join-video.user-active {
  flex: 1 0 100%;
}

.meeting .join-contents.vertical .join-video.single-user {
  flex: 0;
}

.meeting .join-contents.vertical .join-video {
  width: auto;
}

.ptro-holder-wrapper {
  top: 80px !important;
  position: relative !important;
  height: auto !important;
  bottom: 300px;
  z-index: 4;
}

.chat-footer .btn:hover {
  background: #0066c5;
  color: #fff;
}

.person-view {
  width: 40px;
  height: 40px;
  background: #415b6a;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 16px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}

.end-call-chat .btn-primary {
  min-width: 136px;
  background: #fe3f51;
  border-radius: 8px;
  margin: 0px 20px;
  padding: 12px 15px;
  font-size: 16px;
}

/*-----------------
	Email
-----------------------*/

.compose-mail .btn-primary {
  font-size: 14px;
  font-weight: 500;
  min-width: 100%;
  padding: 10px 15px;
  margin-bottom: 30px;
}

.email-menu-blk ul {
  padding: 0;
}

.email-menu-blk ul li {
  list-style: none;
}

.email-menu-blk ul li a {
  color: rgba(51, 52, 72, 0.5);
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 5px;
}

.email-menu-blk ul li a:hover {
  background: rgba(51, 52, 72, 0.05);
  color: #333448;
}

.email-menu-blk ul li.active a {
  background: rgba(51, 52, 72, 0.05);
  color: #333448;
}

.email-menu-blk ul li.active span {
  background: #00d3c7;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
}

.email-menu-blk ul li span {
  font-size: 14px;
  margin-left: auto;
  width: 45px;
  height: 26px;
}

.sub-mails {
  padding: 0;
  position: absolute;
  top: 13px;
  right: 20px;
}

.sub-mails li {
  padding: 0px 5px;
  color: #333448;
  font-size: 14px;
  font-weight: 600;
}

.summer-mail .card .card-header {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 10px;
}

.summer-mail .note-editor.note-frame {
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
}

.chat-user-group .user-titles.user-head-compse h5 {
  color: #2e37a4;
}

.label-blk {
  margin-bottom: 20px;
}

.label-blk h4 {
  margin-bottom: 0;
  color: #333448;
  font-size: 16px;
  font-weight: 600;
}

.label-add-list li .add-list-btn {
  width: 30px;
  height: 30px;
  background: rgba(46, 55, 164, 0.05);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.label-add-list li .add-list-btn:hover {
  background: #234ce3;
  color: #fff;
}

.top-action-right ul li {
  margin-right: 20px;
}

.top-action-right ul li:last-child {
  margin-right: 0px;
}

.top-liv-search.top-action-search .form-control {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 12px;
  border: 0;
}

.top-inbox-blk {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 10px;
  width: 40px;
  height: 40px;
}

.top-inbox-blk .dropdown-menu {
  text-align: center;
  padding: 0;
  border: 0;
  background: linear-gradient(51.64deg,
      rgba(255, 255, 255, 0) 34.2%,
      rgba(255, 255, 255, 0.5) 97.35%,
      rgba(255, 255, 255, 0) 160.66%),
    rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 9px #e1ecff, inset 0px 0px 15px rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(5px);
  border-radius: 5px;
}

.top-inbox-blk .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #ebebf6;
  font-size: 14px;
  font-weight: 600;
  color: #333448;
  padding: 10px;
}

.top-inbox-blk .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0px;
}

.top-recheck .custom_check input {
  position: relative;
}

.top-check .form-check-input {
  border: 2px solid rgba(51, 52, 72, 0.2);
  border-radius: 4px;
  width: 18px;
  height: 18px;
}

.top-check .form-check-input:checked[type="checkbox"] {
  background-color: #234ce3;
}

.form-check-input:checked {
  background-color: #00d3c7;
  border-color: #00d3c7;
}

.select-gender .form-check-input:focus {
  box-shadow: none;
}

.top-check .form-check-input:focus {
  box-shadow: none;
}

.email-content .table-inbox tr {
  background: #fff;
  border-radius: 5px;
}

.email-content .table-inbox tr td {
  vertical-align: middle;
  padding: 15px;
  color: #6586bf;
  white-space: nowrap;
}

.email-img img {
  border-radius: 50px;
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.send-user h4 {
  color: #333448;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.send-user p {
  color: rgba(51, 52, 72, 0.5);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.email-img-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.send-user h4 span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  margin-left: 10px;
  font-size: 10px;
  border-radius: 8px;
}

.send-user .email-market {
  background: rgba(0, 211, 199, 0.15);
  color: #00d3c7;
}

.email-office {
  background: rgba(13, 191, 10, 0.15);
  color: #0dbf0a;
}

.email-bills {
  background: rgba(254, 63, 81, 0.15);
  color: #fe3f51;
}

.email-work {
  background: rgba(255, 182, 39, 0.15);
  color: #ffb627;
}

.send-user h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.compose-mail h3 {
  color: #333448;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}

.mail-send {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.mail-send .btn-primary {
  margin-bottom: 0;
  min-width: 111px;
  border-radius: 12px;
}

.mail-reports ul li .ford-angle {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 10px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 40px;
  height: 40px;
}

.mail-reports ul li .ford-angle:hover {
  background: rgb(35 76 227 / 50%);
}

.mail-reports ul li .ford-angle:hover img,
.mail-reports ul li .ford-angle:hover i {
  filter: invert(1) brightness(100);
}

.mail-reports ul li {
  margin-right: 10px;
}

.mail-reports ul li:last-child {
  margin-right: 0px;
}

.mail-view-list {
  margin-bottom: 25px;
}

.mail-view-list li {
  margin-right: 10px;
}

.mailview-inner p {
  color: rgba(51, 52, 72, 0.5);
  font-size: 13px;
  font-weight: 600;
}

.thank-mail {
  margin-bottom: 40px;
}

.thank-mail h5 {
  color: #35446f;
  font-size: 14px;
  margin-bottom: 0;
}

.thank-mail h4 {
  color: #333448;
  font-size: 16px;
  font-weight: 600;
}

.forward-send .btn-primary {
  padding: 10px 15px;
  padding: 7px 23px;
  margin-bottom: 0;
  border: 2px solid transparent;
  font-size: 14px;
}

.forward-send .forwrd-btn {
  background: transparent;
  border: 2px solid #234ce3;
  color: #234ce3;
  font-weight: 600;
}

.forward-send .forwrd-btn:hover {
  background: #234ce3;
  border: 2px solid #234ce3;
  color: #fff;
}

.forward-send .forwrd-btn:hover img {
  filter: invert(1) brightness(100);
}

.custom-file-upload {
  background: linear-gradient(51.64deg,
      rgba(255, 255, 255, 0) 34.2%,
      rgba(255, 255, 255, 0.2) 97.35%,
      rgba(255, 255, 255, 0) 160.66%),
    rgba(255, 255, 255, 0.2);
  border: 2px solid #ffffff;
  border-radius: 8px;
  display: inline-flex;
  transition: border-color 0.3s;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  margin-top: 0;
  height: 53px;
  color: #000;
  position: relative;
}

.custom-file-upload input {
  display: none;
}

.custom-file-upload .file-upload-text {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.change-user {
  padding: 10px 18px;
  background: #6586bf;
  border-radius: 8px;
  font-size: 16px;
  color: #fff;
  position: absolute;
  right: 0;
  margin: 2px;
  min-width: 177px;
  text-align: center;
}

.sender-img img {
  width: 48px;
  height: 48px;
}

.send-user.send-user-name h4 {
  font-size: 15px;
}

.send-user.send-user-name h4 span {
  padding: 5px 15px;
}

.send-user p span {
  color: #333448;
  font-weight: 600;
}

.mail-reports ul li .ford-angle.star-bg {
  background: transparent;
  margin-right: 0;
}

.ford-angle.star-bg i {
  color: #ffb627;
}

.mail-attach .msg-sub-list li {
  max-width: 100%;
  margin-bottom: 0;
  margin-right: 10px;
}

.mail-attach .msg-sub-list li i {
  color: #fe3f51;
}

.mail-attach {
  margin-bottom: 40px;
}

.view-content-bottom {
  border-bottom: 2px solid #ffffff;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.mail-attach h2 {
  color: #35446f;
  font-size: 16px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.mail-attach h2 a {
  color: #2e37a4;
  font-size: 14px;
}

.setting-title p {
  margin-bottom: 0;
}

/*-----------------
	16. Focus Label
-----------------------*/

.form-focus {
  display: flex;
  height: 50px;
  position: relative;
}

.form-focus .focus-label {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate3d(0, 22px, 0) scale(1);
  -ms-transform: translate3d(0, 22px, 0) scale(1);
  -o-transform: translate3d(0, 22px, 0) scale(1);
  transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  left: 12px;
  top: -8px;
  z-index: 1;
  color: #888;
}

.form-focus.focused .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -14px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .form-control {
  height: 50px;
  padding: 21px 12px 6px;
}

.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}

.form-focus .form-control:focus::-webkit-input-placeholder {
  transition: none;
}

.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}

.profile-basic .cal-icon {
  width: 100%;
}

.form-focus .select2-container .select2-selection--single {
  border: 1px solid #ccc;
  height: 50px;
  border-radius: 0;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 48px;
  right: 7px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}

.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 10px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}

.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #fc6075;
}

.file-size {
  color: #888;
}

/*-----------------
	17. Leave
-----------------------*/

.filter-row .btn {
  border-radius: 0;
  min-height: 50px;
  padding: 12px;
  text-transform: uppercase;
}

.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}

.action-label .label {
  display: inline-block;
  min-width: 85px;
  padding: 0.5em 0.6em;
}

.action-label i {
  margin-right: 3px;
}

.action-label .dropdown-menu>li>a {
  padding: 3px 10px;
}

/*-----------------
	18. Employee
-----------------------*/

.action-icon {
  color: #777;
  font-size: 14px;
  background: rgba(51, 52, 72, 0.05);
  border-radius: 10px;
  width: 31px;
  height: 31px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-left: auto;
}

.table .dropdown-menu {
  font-size: 12px;
}

.profile-widget {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.profile-widget .user-name>a {
  color: #333;
}

.dropdown.profile-action {
  position: absolute;
  right: 5px;
  text-align: right;
  top: 10px;
}

.profile-action .dropdown-toggle:after {
  display: none;
}

.profile-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 80px;
}

.profile-img-sm {
  cursor: pointer;
  height: 70px;
  margin: 0 auto;
  position: relative;
  width: 70px;
}

.profile-img .avatar {
  font-size: 24px;
  height: 80px;
  line-height: 80px;
  margin: 0;
  width: 80px;
}

.mobile-no>a {
  color: #777;
  display: inline-block;
}

.staff-mail>a {
  color: #777;
  display: inline-block;
  margin-top: 5px;
}

.staff-action-btns {
  margin-top: 10px;
}

.staff-id {
  margin-top: 5px;
}

.view-icons {
  float: right;
  margin-right: 10px;
}

.view-icons .btn {
  color: #888;
  font-size: 18px;
  margin-right: 5px;
  padding: 4px;
}

.view-icons .btn.active {
  color: #333;
}

.attent-status {
  background: rgba(0, 211, 199, 0.15);
  border-radius: 6px;
  width: 26px;
  height: 26px;
  color: #00d3c7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.present-table {
  background: rgba(0, 211, 199, 0.15);
  color: #00d3c7;
}

.absent-table {
  background: rgba(255, 54, 103, 0.1);
  color: #ff3667;
}

.month-table h5 {
  font-weight: 500;
  font-size: 14px;
}

.attent-table thead tr th:first-child {
  color: #333448;
}

.attent-table thead tr th {
  font-weight: 600;
  font-size: 14px;
  color: #2e37a4;
}

.attent-table .week-days {
  color: rgba(51, 52, 72, 0.2);
}

/*-----------------
	19. Events
-----------------------*/

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 30px;
}

a.fc-event:not([href]) {
  color: #fff;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-family: "Rubik", sans-serif;
}

.fc-day {
  background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #00d3c7;
  color: #fff;
  text-shadow: none;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px;
  text-align: center;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
}

/*-----------------
	20. Profile
-----------------------*/

.card-box.profile-header {
  margin: 0;
}

.profile-details {
  text-align: center;
}

.personal-info li .title {
  color: #515365;
  float: left;
  font-weight: 500;
  margin-right: 30px;
  width: 30%;
  font-size: 14px;
}

.personal-info li .text {
  color: #888da8;
  display: block;
  overflow: hidden;
  font-size: 14px;
}

.personal-info li {
  margin-bottom: 10px;
}

.personal-info {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.personal-info .text>a {
  color: #2e37a4;
  font-size: 14px;
}

.profile-view .profile-img-wrap {
  height: 150px;
  width: 150px;
}

.profile-view .profile-img {
  width: 150px;
  height: 150px;
}

.profile-view .profile-img .avatar {
  font-size: 24px;
  height: 150px;
  line-height: 150px;
  margin: 0;
  width: 150px;
}

.profile-view .profile-basic {
  margin-left: 170px;
}

.staff-msg {
  margin-top: 30px;
}

.experience-box {
  position: relative;
}

.experience-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.experience-list:before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 8px;
  position: absolute;
  top: 8px;
  width: 2px;
}

.experience-list>li {
  position: relative;
}

.experience-list>li:last-child .experience-content {
  margin-bottom: 0;
}

.experience-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px;
}

.experience-list>li .experience-user {
  background: #fff;
  height: 10px;
  left: 4px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 4px;
  width: 10px;
}

.experience-list>li .experience-content {
  background-color: #fff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}

.experience-list>li .experience-content .timeline-content {
  color: #9e9e9e;
}

.experience-list>li .experience-content .timeline-content a.name {
  color: #616161;
  font-weight: bold;
  font-size: 14px;
}

.experience-list>li .time {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 1.35;
}

.before-circle {
  background-color: #ddd;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.skills>span {
  border: 1px solid #ccc;
  border-radius: 500px;
  display: block;
  margin-bottom: 10px;
  padding: 6px 12px;
  text-align: center;
}

.profile-info-left {
  /*border-right: 2px dashed #ccc;*/
}

.profile-info-left h3 {
  font-size: 20px;
}

.profile-info-left .staff-id {
  font-size: 15px;
}

.profile-tabs .nav-tabs {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.alert {
  font-size: 14px;
}

/*-----------------
	21. Notifications
-----------------------*/

/* .notifications {
  padding: 0;
} */
.notifications .drop-scroll {
  height: 100%;
  width: 400px;
}

.notifications.msg-noti .drop-scroll {
  width: 310px;
}

.notifications .notification-heading {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.45;
  color: #616161;
}

.notifications .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notifications ul.notification-list>li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}

.notifications ul.notification-list>li:last-child {
  border-bottom: none;
}

.notifications ul.notification-list>li a {
  display: block;
  padding: 12px;
  border-radius: 2px;
}

.notifications ul.notification-list>li a:hover {
  background-color: #fafafa;
}

.drop-scroll {
  overflow-y: scroll;
  position: relative;
}

.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
}

.topnav-dropdown-header,
.topnav-dropdown-footer {
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
}

.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333;
}

.user-menu.nav>li>a .badge {
  color: #fff;
  font-size: 60%;
  font-weight: 700;
  position: absolute;
  right: 3px;
  top: 6px;
}

.user-menu.nav>li>a>i {
  font-size: 16px;
  line-height: 55px;
}

.noti-details {
  color: #989c9e;
  margin: 0;
}

.noti-time {
  margin: 0;
}

.noti-title {
  color: #333;
}

/*-----------------
	22. Roles & Permissions
-----------------------*/

.roles-menu {
  margin-top: 20px;
}

.roles-menu>ul {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.roles-menu>ul>li {
  display: block;
  position: relative;
}

.roles-menu>ul>li>a {
  border-left: 3px solid transparent;
  color: #333;
  display: block;
  padding: 10px 15px;
  position: relative;
}

.roles-menu>ul>li.active>a {
  border-color: #009efb;
  color: #009efb;
}

.roles-menu>ul>li>a:hover {
  border-color: #009efb;
  background-color: #eee;
}

.material-switch>input[type="checkbox"] {
  display: none;
}

.material-switch>label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}

.material-switch>label:before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: "";
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch>label:after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: "";
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch>input[type="checkbox"]:checked+label:before {
  background: inherit;
  opacity: 0.5;
}

.material-switch>input[type="checkbox"]:checked+label:after {
  background: inherit;
  left: 20px;
}

.role-action {
  display: none;
  float: right;
  position: absolute;
  right: 8px;
  top: 8px;
}

.roles-menu>ul>li:hover .role-action {
  display: block;
}

.slide-nav .sidebar {
  margin-left: 0;
}

.action-circle {
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.action-circle .material-icons {
  color: #ccc;
  font-size: 18px;
  vertical-align: -3px;
}

.action-circle.completed {
  background: #35ba67;
  border: 1px solid #2fa65c;
}

.action-circle.completed .material-icons {
  color: #fff;
}

.action-circle.large {
  height: 24px;
  width: 24px;
}

.action-circle.large .material-icons {
  font-size: 16px;
  vertical-align: -4px;
}

.sidebar-overlay {
  display: none;
  position: fixed;
  z-index: 90;
  height: 100%;
  left: 0;
  top: 50px;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
}

.menu-opened .sidebar-overlay {
  display: block;
}

.sidebar-menu.opened {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  margin-left: 0;
}

html.menu-opened {
  overflow: hidden;
}

html.menu-opened body {
  overflow: hidden;
}

.chat-profile-view.opened {
  margin-right: 0;
}

.menu-opened .message-view.chat-view {
  width: 100%;
}

/*-----------------
	23. Chat Right Sidebar
-----------------------*/

.fixed-header .custom-menu {
  margin: 1px 0 0;
}

.topnav-dropdown-footer {
  border-top: 1px solid #eee;
}

.notification-box {
  bottom: 0;
  margin-right: -300px;
  position: fixed;
  right: 0;
  top: 70px;
  transition: all 0.4s ease 0s;
  width: 300px;
  z-index: 101;
  border-left: 1px solid #ddd;
  overflow-x: hidden;
}

.open-msg-box .notification-box {
  margin-right: 0;
  z-index: 9;
}

.msg-sidebar {
  background-color: #fff;
}

.list-box {
  list-style: none;
  padding-left: 0;
}

.list-item.new-message {
  background-color: rgba(0, 0, 0, 0.2);
}

.list-item {
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
  position: relative;
}

.list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}

.files-icon {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}

.files-icon i {
  color: #777;
  font-size: 20px;
}

.list-body {
  padding: 0 0 0 50px;
}

.message-author {
  color: #333448;
  float: left;
  font-weight: bold;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
}

.message-time {
  color: #888;
  float: right;
  font-size: 11px;
}

.message-content {
  color: #333;
  font-size: 11px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*-----------------
	24. Invoice
-----------------------*/

.invoice-details,
.invoice-payment-details>li span {
  float: right;
  text-align: right;
}

.attach-files>ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.attach-files li {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.attach-files li img {
  width: 50px;
}

.file-remove {
  color: #f00;
  position: absolute;
  right: -6px;
  top: -7px;
}

.attach-files li:last-child {
  margin-right: 0;
}

.inv-logo {
  height: auto;
  margin-bottom: 20px;
  max-height: 100px;
  width: auto;
}

.form-amt {
  border: 1px solid #fff;
}

.page-title-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  text-align: center;
}

.invoices-links:last-child {
  margin-right: 0px;
}

.invoices-links {
  color: #1b2559;
  margin-right: 20px;
}

.invoice-inner-datepic {
  border: 0;
  padding-left: 0;
}

.invoice-inner-datepic {
  border: 0;
  padding-left: 0;
}

.invoices-form .inovices-month-info .form-control {
  height: 42px;
}

.app-listing {
  padding: 0;
  margin: 0 -15px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.app-listing>li {
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}

.multipleSelection {
  position: relative;
  margin-bottom: 20px;
  z-index: 99;
}

.multipleSelection .selectBox,
.SortBy .selectBoxes {
  position: relative;
  background: #fff;
  width: 100%;
  padding: 11px 10px;
  font-weight: 400;
  background: #ffffff;
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.selectBox p {
  color: #1b2559;
  font-weight: 500;
  font-size: 14px;
}

.selectBox p .select-icon {
  color: #2e37a4;
  width: 20px;
  height: 20px;
}

.multipleSelection #checkBoxes,
.SortBy #checkBox {
  display: none;
  position: absolute;
  width: 300px;
  left: 0;
  z-index: 1;
  margin-top: 10px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #f2ecff;
  box-shadow: 0px 4px 44px rgb(231 234 252 / 75%);
  border-radius: 6px;
}

.multipleSelection #checkBoxes::before,
.SortBy #checkBox::before {
  border: 7px solid #f2ecff;
  border-color: transparent transparent #ffffff #ffffff;
  box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
  content: "";
  left: 30px;
  position: absolute;
  top: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.checkbox-title {
  font-weight: 600;
  font-size: 15px;
  color: #2e37a4;
  margin-bottom: 14px;
}

#checkBoxes .form-custom {
  margin-bottom: 14px;
}

.selectBox-cont {
  height: 220px;
  overflow-y: auto;
  padding-right: 5px;
}

.custom_check {
  color: #1b2559;
  display: inline-block;
  position: relative;
  font-size: 13px;
  margin-bottom: 15px;
  padding-left: 30px;
  cursor: pointer;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #c4c4c4;
  background-color: transparent;
  border-radius: 0;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom_check input:checked~.checkmark:after {
  display: block;
}

.custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  color: #ffffff;
  font-size: 9px;
}

#checkBoxes .btn,
#checkBox .btn {
  font-size: 13px;
  font-weight: 500;
  padding: 8px 0px;
}

#checkBoxes .btn-grey {
  background: #55ddff30;
  color: #1b2559;
  margin-top: 10px;
  border: none;
}

.feather {
  width: 18px;
  height: 18px;
}

.report-btn {
  margin-bottom: 20px;
}

.report-btn .btn {
  font-weight: 600;
  color: #2e37a4;
  background: #ffffff;
  border: 2px solid #2e37a4;
  border-radius: 8px;
  width: 100%;
  padding: 11px 0;
}

.selectBox-cont-one {
  overflow-y: inherit;
}

.date-picker {
  width: 50%;
  float: left;
  padding-right: 10px;
}

#checkBoxes .form-custom {
  margin-bottom: 14px;
}

#checkBoxes .date-picker .form-custom .form-control {
  padding-left: 10px;
}

.form-custom.cal-icon:after {
  font-size: 14px;
}

.date-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.date-list ul li {
  width: 100%;
  padding: 0;
  padding-bottom: 5px;
}

#checkBoxes .btn,
#checkBox .btn {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0px;
}

.date-list .date-btn {
  background: #fff;
  color: #1b2559;
  border: 1px solid #e7eafc;
  border-radius: 4px;
  padding: 10px 0 !important;
  width: 100%;
}

.date-list .date-btn:hover {
  background: #00d3c7;
  color: #fff;
  border: 1px solid #e7eafc;
}

.custom_check input:checked~.checkmark {
  background-color: #2e37a4;
  border-color: #2e37a4;
}

.invoices-tabs-card {
  background: transparent;
  box-shadow: none;
  border: 0;
}

.inovices-card .card-body {
  padding: 15px;
}

.invoices-main-tabs {
  border-bottom: 2px solid #f3f3f3;
  padding-bottom: 10px;
}

.invoices-tabs ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.invoices-tabs ul li {
  position: relative;
  display: inline-block;
  margin-right: 36px;
}

.invoices-tabs ul li a.active {
  color: #2e37a4;
  border-bottom: 2px solid #2e37a4;
}

.invoices-settings-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.invoices-settings-icon {
  color: #2e37a4;
  margin-right: 20px;
}

.invoices-settings-btn .btn {
  font-weight: 600;
  color: #fff;
  background-color: #2e37a4;
  padding: 12px 0;
  min-width: 167px;
  border: 1px solid #2e37a4;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.inovices-card {
  border-radius: 5px;
}

.inovices-widget-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.inovices-widget-icon img {
  width: 50px;
}

.inovices-widget-header .inovices-amount {
  font-weight: 600;
  font-size: 20px;
  color: #2e37a4;
}

.inovices-all {
  font-weight: 500;
  color: #1b2559;
  margin-top: 16px;
}

.inovices-all span {
  font-size: 10px;
  margin-left: 15px;
}

.invoices-tabs ul li a {
  font-weight: 500;
  font-size: 15px;
  color: #1b2559;
  padding-bottom: 24px;
}

table .badge {
  border-radius: 6px;
  display: inline-block;
  font-size: 13px;
  min-width: 105px;
  padding: 8px 20px;
  font-weight: 500;
  text-align: center;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}

.bg-danger-light {
  background-color: rgb(255 218 218 / 49%) !important;
  color: #ff0000 !important;
}

.invoice-link {
  color: #009efb;
}

.invoices-links.active {
  color: #2e37a4;
}

.invoices-links:last-child {
  margin-right: 0px;
}

.invoices-links {
  color: #1b2559;
  margin-right: 20px;
}

.table .bg-success-dark {
  background: #008f64;
}

.table .bg-danger-dark {
  background: #ff0000;
}

.invoices-grid-card {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgb(228 228 228 / 25%);
  border-radius: 10px;
  color: #1b2559;
}

.invoices-grid-card .card-header {
  padding: 20px;
  border: 0;
}

.invoice-grid-link {
  color: #1b2559;
  font-size: 14px;
}

.card-middle {
  padding: 20px;
  background: #55ddff30;
}

.card-middle-avatar {
  align-items: center;
  display: inline-flex;
  font-size: 15px;
  margin: 0;
}

.card-middle-avatar a {
  color: #1b2559;
}

.invoices-grid-card .card-body {
  padding: 20px;
}

.invoices-grid-card .card-body span {
  font-size: 14px;
}

.invoices-grid-card .card-footer {
  padding: 20px;
  border: 0;
}

.card-footer:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.invoices-settings-btn .btn svg {
  margin-right: 10px;
}

.preclincs-title {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
}

.report-btn .btn:hover {
  color: #ffffff;
  background: #2e37a4;
  border: 2px solid #2e37a4;
}

.report-btn .btn:hover img {
  filter: invert(0) brightness(100);
}

.invoices-settings-btn .btn:hover {
  background: #00d3c7;
  border: 1px solid #00d3c7;
}

.invoices-tabs>ul>li>a:hover {
  color: #2e37a4;
}

a.invoices-settings-icon:hover {
  color: #00d3c7;
}

a.btn.delete-invoice-btn:hover {
  background: #474d52;
}

a.btn.save-invoice-btn:hover {
  background: #00d3c7;
}

a.invoices-preview-link:hover {
  color: #00d3c7;
}

li.breadcrumb-item.invoices-breadcrumb-item:hover>a {
  color: #00d3c7;
}

li.breadcrumb-item.invoices-breadcrumb-item:hover>a i {
  background: #00d3c7;
}

.invoice-info>strong>a:hover {
  color: #00d3c7;
}

.invoice-inner-head span a:hover {
  color: #00d3c7;
}

.field-box>a:hover {
  background: #00d3c7;
}

.invoice-total-inner a:hover {
  color: #00d3c7;
}

h2.card-middle-avatar:hover>a {
  color: #00d3c7;
}

.upload-sign button:hover {
  background: #00d3c7;
}

.payment-details:hover {
  background: #00d3c7;
}

.invoices-upload-btn:hover .upload {
  background-color: #00d3c7;
}

.invoice-setting-btn .cancel-btn:hover {
  background-color: #474d52;
}

.invoice-setting-btn button:last-child:hover {
  background-color: #00d3c7;
}

.settings-btns button:nth-child(1):hover {
  background-color: #00d3c7;
}

.settings-btn:hover .upload {
  background-color: #00d3c7;
}

.nav-tabs.menu-tabs .nav-link:hover {
  background: #2e37a4;
  color: #fff;
}

.form-row.links-cont a:hover {
  background: #00d3c7;
}

.form-row.links-cont button:hover {
  background: #00d3c7;
}

a.btn.add-links:hover {
  background: #00d3c7;
}

.breadcrumb-item a:hover {
  color: #00d3c7;
}

.breadcrumb-item.active {
  color: rgba(46, 55, 164, 0.5);
}

.breadcrumb-item+.breadcrumb-item::before {
  display: none;
}

.breadcrumb-item+.breadcrumb-item i {
  color: #2e37a4;
}

/*-----------------
	25. Doctors
-----------------------*/

.doctor-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto 15px;
  position: relative;
  width: 80px;
}

.doctor-img .avatar {
  font-size: 24px;
  height: 80px;
  line-height: 80px;
  margin: 0;
  width: 80px;
}

.doc-prof {
  color: #777;
  font-size: 12px;
  margin-bottom: 10px;
}

.doctor-name {
  margin: 0;
}

.doctor-name a {
  color: #333;
}

.see-all {
  margin-top: 30px;
  text-align: center;
}

.see-all .see-all-btn {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  color: #777;
  display: inline-block;
  padding: 10px 20px;
}

.comman-table.custom-table thead tr th,
.comman-table.custom-table tbody tr td {
  color: #333448;
  font-size: 14px;
  font-weight: 500;
}

.comman-table.custom-table thead tr th {
  font-weight: 600;
}

.show-entire .dataTables_length {
  display: none;
}

.doctor-table-blk h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.doctor-table-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.doctor-search-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.doctor-search-blk .top-nav-search {
  margin-left: 15px;
}

.doctor-search-blk .top-nav-search form {
  margin: 0px;
}

.add-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.add-group .btn-primary {
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  color: #2e37a4;
  width: 40px;
  height: 40px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.add-group .btn-primary:hover {
  border: 0;
  background: #2e37a4;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.add-group .btn-primary:hover img {
  filter: invert(1) brightness(100);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.table.comman-table>thead>tr>th,
.table.comman-table>tbody>tr>td {
  padding: 15px 15px;
}

.card-table .card-body {
  padding: 0;
}

.page-table-header {
  padding: 15px 15px 0px 15px;
}

.card-table .dataTables_info {
  padding-left: 15px;
  font-size: 14px;
}

.profile-image a {
  color: #333448;
}

.profile-image a:hover {
  color: #2e37a4;
}

.scroll-indicator-none {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-indicator-none::-webkit-scrollbar {
  display: none;
}

/*-----------------
	26. Add Doctor
-----------------------*/

.profile-upload {
  display: flex;
}

.upload-img {
  margin-right: 10px;
}

.upload-img img {
  border-radius: 4px;
  height: 40px;
  width: 40px;
}

.upload-input {
  width: 100%;
}

.gen-label {
  display: block;
  margin-bottom: 18px;
}

.submit-btn {
  border-radius: 50px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  min-width: 200px;
  padding: 8px 20px;
  text-transform: uppercase;
}

.delete-modal .modal-body .btn {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  width: 90px;
  color: #333;
}

.delete-modal .modal-body .btn.btn-danger {
  color: #fff;
}

.delete-modal .modal-body>h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0 0;
}

.custom-badge {
  border-radius: 8px;
  display: inline-block;
  font-size: 12px;
  min-width: 115px;
  padding: 5px 15px;
  text-align: center;
  border: 0;
}

.custom-badge button {
  padding: 0 10px;
}

.custom1-badge {
  border-radius: 8px;
  display: inline-block;
  font-size: 12px;
  min-width: 115px;
  text-align: center;
  border: 0;
}

.status-red,
a.status-red {
  background-color: #ffe5e6;
  color: #fe0000;
}

.status-green,
a.status-green {
  background: rgba(0, 211, 199, 0.1);
  color: #00d3c7;
}

.status-orange,
a.status-orange {
  background-color: #fef5e4;
  color: #ff9b01;
}

.status-blue,
a.status-blue {
  background-color: #e5f3fe;
  color: #008cff;
}

.status-purple,
a.status-purple {
  background-color: #f3e7fd;
  color: #8f13fd;
}

.status-pink,
a.status-pink {
  background-color: #ffe5f6;
  color: #ff01a2;
}

.status-grey,
a.status-grey {
  background-color: #ddd;
  color: #818181;
}

.btn-grey {
  background-color: #b2b2b2;
  border: 1px solid #b2b2b2;
}

.modal-backdrop {
  background-color: #fff;
}

.modal-backdrop.show {
  opacity: 0.9;
}

.invoices-view {
  width: 70%;
  float: right;
}

.local-forms.form-group {
  position: relative;
  margin-bottom: 30px;
}

.local-forms label {
  font-size: 12px;
  color: #333448;
  font-weight: 500;
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  margin-bottom: 0;
  padding: 0px 5px;
  z-index: 1;
}

.local-forms .select2-container .select2-selection--single {
  height: 45px;
}

.local-forms .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
}

.local-forms .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 42px;
}

.form-heading h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}

.select-gender .gen-label {
  margin-bottom: 5px;
  color: #333448;
  font-size: 12px;
  font-weight: 500;
}

.select-gender .form-check-label {
  color: rgba(51, 52, 72, 0.5);
  font-weight: 500;
  font-size: 14px;
}

.select-gender .form-check-input {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  margin-top: 0;
  cursor: pointer;

  /* vertical-align: middle; */
}

.local-top-form {
  position: relative;
}

.local-top-form .local-top {
  font-size: 12px;
  color: #333448;
  font-weight: 500;
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  margin-bottom: 0;
  padding: 0px 5px;
  z-index: 99;
}

.settings-btn.upload-files-avator .upload {
  width: 148px;
  background: #f4f5fa;
  color: #2e37a4;
  font-size: 14px;
  font-weight: 500;
  right: -2px;
  top: -2px;
  height: 46px;
}

.settings-btn.upload-files-avator {
  min-height: 45px;
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 10px;
}

.doctor-submit .btn-primary {
  min-width: 131px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
  padding: 10px 15px;
}

.doctor-submit .cancel-form {
  background: rgba(51, 52, 72, 0.05);
  color: #333448;
  padding-right: 60px;
}

.doctor-submit .cancel-form:hover {
  background: #2e37a4;
  color: #fff;
  border: 1px solid #2e37a4;
}

.calendar-icon:after {
  color: #333448;
  content: "\f073";
  display: block;
  font-family: "Font Awesome 6 Free";
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.top-nav-search.table-search-blk .btn {
  min-height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/*-----------------
	Doctor Profile
-----------------------*/

.doctor-profile-head {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 10px;
}

.profile-bg-img img {
  width: 100%;
  border-radius: 10px;
}

.profile-user-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.profile-user-img {
  margin-right: 20px;
  position: relative;
  top: -30px;
}

.profile-user-img img {
  border-radius: 50%;
  width: 141px;
}

.names-profiles h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
}

.names-profiles h5 {
  font-size: 14px;
  font-weight: 500;
  color: #2e37a4;
  margin-bottom: 0;
}

.profile-edit-icon {
  position: absolute;
  border-radius: 50px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.profile-edit-icon img {
  width: 25px;
}

.follow-group {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.doctor-follows {
  text-align: center;
}

.doctor-follows h5 {
  color: rgba(51, 52, 72, 0.5);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.doctor-follows h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.follow-btn-group {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.follow-btn-group .btn-info {
  /* min-width: 127px; */
  width: 109px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  padding: 9px 15px;
}

.follow-btn-group .follow-btns {
  background: #2e37a4;
  border: 1px solid #2e37a4;
  margin-right: 15px;
}

.follow-btn-group .follow-btns:hover {
  background: #00d3c7;
  border: 1px solid #00d3c7;
}

.follow-btn-group .message-btns {
  background: #00d3c7;
  border: 1px solid #00d3c7;
}

.follow-btn-group .message-btns:hover {
  background: #2e37a4;
  border: 1px solid #2e37a4;
}

.personal-activity {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.views-personal {
  margin-left: 10px;
}

.views-personal h5 {
  color: rgba(51, 53, 72, 0.5);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.views-personal h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.personal-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
}

.personal-icons i {
  color: #3d5ee1;
}

.doctor-personals-grp .card {
  border-radius: 10px;
  box-shadow: none;
}

.heading-detail h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 25px;
}

.heading-detail p {
  color: rgba(51, 53, 72, 0.5);
  font-size: 14px;
  font-weight: 500;
}

.hello-park h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid rgba(46, 55, 164, 0.1);
}

.hello-park p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 53, 72, 0.5);
}

.skills-head {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.skills-head h5 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: rgba(51, 53, 72, 0.5);
}

.skills-head p {
  margin-bottom: 0;
  color: rgba(51, 53, 72, 0.5);
  font-size: 15px;
  font-weight: 500;
}

.skill-statistics {
  margin-bottom: 25px;
}

.skill-statistics .progress {
  height: 4px;
}

.bg-operations {
  background: #2e37a4;
}

.bg-statistics {
  background: #00d3c7;
}

.bg-haemoglobin {
  background: #ff3667;
}

.bg-endoscopic {
  background: #ffbf42;
}

.bg-visit {
  background: #333548;
}

.setting-form-blk .local-forms label {
  font-size: 12px;
  color: rgba(51, 52, 72, 0.5);
}

.setting-form-blk .local-forms .form-control::placeholder {
  color: rgba(51, 52, 72, 0.5);
  font-size: 14px;
}

.about-info h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  font-size: 18px;
  font-weight: 500;
}

.about-info h4 i {
  color: #8c8c8c;
  background: rgba(51, 53, 72, 0.05);
  border-radius: 10px;
  width: 31px;
  height: 31px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.about-info {
  margin-bottom: 25px;
}

.doctor-up-files label {
  font-size: 15px;
  font-weight: 500;
}

.doctor-up-files label.file-upload.image-upbtn {
  background: #444;
  border: 1px solid #444;
  border-radius: 8px;
  color: #fff;
  padding: 6px 19px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.doctor-up-files label.file-upload.image-upbtn:hover {
  background: #000;
  border: 1px solid #000;
}

.doctor-up-files label.file-upload {
  position: relative;
  overflow: hidden;
}

.doctor-up-files label.file-upload input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.doctor-up-files.profile-edit-icon label.file-upload.profile-upbtn {
  cursor: pointer;
}

.list-space {
  padding: 0;
  margin: 0;
}

.about-me-list ul li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.about-me-list ul li:last-child {
  margin-bottom: 0;
}

.about-me-list ul li h4 {
  min-width: 50%;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}

.about-me-list ul li span {
  min-width: 50%;
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 53, 72, 0.5);
}

.tab-content-set {
  border-bottom: 4px solid rgba(46, 55, 164, 0.05);
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.tab-content-set ul li a.active::after,
.tab-content-set ul li a:hover::after {
  position: absolute;
  content: "";
  background: #2e37a4;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: -29px;
}

.tab-content-set ul li a.active,
.tab-content-set ul li a:hover {
  color: #2e37a4;
}

.tab-content-set ul li a.active .set-about-icon,
.tab-content-set ul li a:hover .set-about-icon {
  background: rgba(46, 55, 164, 0.05);
}

.tab-content-set ul li a.active .set-about-icon img,
.tab-content-set ul li a:hover .set-about-icon img {
  filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(50%) contrast(340%);
}

.tab-content-set li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(51, 53, 72, 0.5);
  font-size: 15px;
  font-weight: 600;
  position: relative;
}

.tab-content-set li {
  padding-right: 20px;
}

.set-about-icon {
  background: rgba(51, 53, 72, 0.05);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.detail-personal h2 {
  font-size: 15px;
  font-weight: 500;
}

.detail-personal h3 {
  font-size: 12px;
  font-weight: 500;
  color: rgba(51, 53, 72, 0.5);
  margin-bottom: 0;
}

.personal-list-out {
  margin-bottom: 30px;
}

.profile-table.custom-table thead tr th,
.profile-table.custom-table tbody tr td {
  font-size: 14px;
  color: #333548;
  font-weight: 500;
  padding: 10px 0px;
}

.profile-table.custom-table thead tr th {
  color: #333548;
}

.profile-table.custom-table tbody tr td {
  color: rgba(51, 53, 72, 0.5) !important;
}

.profile-table.custom-table tbody tr {
  box-shadow: none;
}

.profile-check-blk .remember-me {
  margin-bottom: 20px;
}

.profile-check-blk .custom_check input:checked~.checkmark {
  background-color: #2e37a4;
  border-color: #2e37a4;
}

.profile-check-blk .form-heading h4 {
  font-size: 16px;
}

.patient-profile-table tbody tr td:nth-child(4) {
  color: rgba(51, 52, 72, 0.5);
}

/*-----------------
	Staffs
-----------------------*/

.staff-search-table {
  padding: 0px 15px;
  margin-top: 30px;
}

.submit-list-form {
  width: 100%;
}

.status-staff {
  background: #ffffff;
  border: 1px solid rgba(46, 55, 164, 0.1);
  box-shadow: 5px 5px 20px rgba(51, 52, 72, 0.05);
  border-radius: 12px;
  padding: 0;
}

.status-staff .dropdown-item {
  border-bottom: 1px solid #ebebf6;
  font-size: 12px;
  padding: 10px;
  text-align: center;
}

.status-staff .dropdown-item:last-child {
  border: 0;
}

/*-----------------
	27. Payslip
-----------------------*/

.payslip-title {
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
}

.pay-head-roll h5 {
  color: #2e37a4;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.invoice-counts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.payroll-head-clinic {
  margin-bottom: 20px;
}

.payrolss-counts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 2px solid rgba(46, 55, 164, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.payrolss-counts h5 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}

.payslip-head-roll h5 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
}

/*-----------------
	28. Attendance
-----------------------*/

.half-day {
  width: 15px;
}

/*-----------------
	29. Inbox
-----------------------*/

.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
  cursor: pointer;
}

.mail-list {
  list-style: none;
  padding: 0;
}

.mail-list>li>a {
  color: #333;
  display: block;
  padding: 10px;
}

.mail-list>li.active>a {
  color: #009efb;
  font-weight: bold;
}

.unread .name,
.unread .subject,
.unread .mail-date {
  color: #000;
  font-weight: 600;
}

.table-inbox .starred.fa-star {
  color: #ffd200;
}

.table.table-inbox>tbody>tr>td,
.table.table-inbox>tbody>tr>th,
.table.table-inbox>tfoot>tr>td,
.table.table-inbox>tfoot>tr>th,
.table.table-inbox>thead>tr>td,
.table.table-inbox>thead>tr>th {
  border-bottom: 1px solid #f2f2f2;
  border-top: 0;
}

.table-inbox {
  font-size: 14px;
  margin-bottom: 0;
}

.note-editor.note-frame {
  border: 1px solid #ccc;
  box-shadow: inherit;
}

.note-editor.note-frame .note-statusbar {
  background-color: #fff;
}

.note-editor.note-frame.fullscreen {
  top: 60px;
}

.mail-title {
  font-weight: bold;
  text-transform: uppercase;
}

.form-control.search-message {
  border-radius: 4px;
  margin-left: 5px;
  width: 180px;
  padding: 0.375rem 0.75rem;
  min-height: 35px;
  margin-bottom: 5px;
}

.table-inbox tr {
  cursor: pointer;
}

table.table-inbox tbody tr.checked {
  background-color: #ffffcc;
}

.mail-label {
  margin-right: 5px;
}

/*-----------------
	29. Mail View
-----------------------*/

.attachments {
  list-style: none;
  margin: 0;
  padding: 0;
}

.attachments li {
  border: 1px solid #eee;
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 200px;
}

.attach-file {
  color: #777;
  font-size: 70px;
  padding: 10px;
  text-align: center;
  min-height: 153px;
}

.attach-file>i {
  line-height: 133px;
}

.attach-info {
  background-color: #f4f4f4;
  padding: 10px;
}

.attach-filename {
  color: #777;
  font-weight: bold;
}

.attach-filesize {
  color: #999;
  font-size: 12px;
}

.attach-file img {
  height: auto;
  max-width: 100%;
}

.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px;
}

.mailview-footer .btn-white {
  min-width: 102px;
}

.sender-name {
  display: block;
}

.receiver-name {
  color: #777;
}

.right-action {
  text-align: right;
}

.mail-view-title {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.mail-view-action {
  float: right;
}

.mail-sent-time {
  float: right;
}

/*-----------------
	31. Blog
-----------------------*/

.blog {
  position: relative;
  margin: 0 0 25px;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
}

/* .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  height: 282px;
} */

element.style {
  height: 282.781px;
}

.feather.feather-eye {
  width: 18px;
}

.blog-image {
  overflow: hidden;
}

.blog-image,
.blog-image>a,
.blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.blog-image img {
  -moz-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  border-radius: 16px;
}

.blog-image a:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.blog-image {
  margin-bottom: 30px;
}

.blog-content {
  position: relative;
}

.blog-title {
  color: #000;
  font-weight: normal;
  margin: 0 0 20px;
}

.media {
  display: flex;
  align-items: center;
}

.blog-content p {
  color: rgba(51, 52, 72, 0.75);
  font-size: 14px;
  line-height: 25px;
  margin: 0 0 25px;
}

.read-more {
  color: #282628;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 20px;
}

.read-more:hover {
  color: #009efb;
  margin-left: 10px;
  -moz-transition: all 0.2s linear 0s;
  -ms-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}

.read-more i {
  color: #2e37a4;
  font-size: 18px;
  margin-right: 3px;
}

.blog-info {
  border: 1px solid #eaeaea;
  color: #909090;
  font-size: 12px;
  margin-bottom: 0;
  padding: 12px 20px;
}

.blog-info a {
  color: #909090;
  margin-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-info a:last-child {
  margin-right: 0;
}

.blog-info a:hover {
  color: #00d3c7;
}

.blog-info i {
  color: rgba(51, 52, 72, 0.5);
  font-size: 18px;
  margin-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.post-left {
  float: right;
}

.post-right {
  float: left;
}

.post-left ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.post-left ul li {
  float: left;
  margin-right: 20px;
}

.post-left ul li:last-child {
  margin-right: 0;
}

.video {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
}

.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.widget {
  margin-bottom: 30px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.widget h5 {
  color: #333548;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 20px;
}

.search-form .form-control {
  border: 1px solid #eaeaea;
  border-radius: 0;
  border-right: 0;
  box-shadow: inherit;
  height: 40px;
  color: #ababab;
}

.search-form .btn-primary {
  background-color: transparent;
  border-color: #eaeaea;
  border-left: none;
  color: #009efb;
  height: 40px;
  border-radius: 0;
}

.latest-posts {
  margin: 0;
  padding: 0;
}

.latest-posts li {
  display: table;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.latest-posts li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}

.post-thumb {
  width: 80px;
  float: left;
  overflow: hidden;
  border-radius: 16px;
}

.post-thumb a img {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  border-radius: 16px;
}

.post-thumb a:hover img {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.post-info {
  margin-left: 95px;
}

.post-info h4 {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  margin: 0 0 10px;
}

.post-info h4 a {
  color: #333448;
  font-size: 14px;
  font-weight: 600;
}

.post-info p {
  color: #2e37a4;
  font-size: 12px;
  margin: 0;
  font-weight: 500;
}

.post-info p i {
  color: #2e37a4;
  font-size: 16px;
  margin-right: 4px;
}

.categories {
  list-style: none;
  margin: 0;
  padding: 0;
}

.categories li {
  padding: 12px 0;
  font-size: 16px;
  color: #333448;
  font-weight: 600;
}

.categories li:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.categories li a {
  color: #656565;
  font-size: 14px;
}

.categories li a:hover {
  color: #009efb;
  margin-left: 10px;
  -moz-transition: all 0.2s linear 0s;
  -ms-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}

.categories>li>a>i {
  color: #009efb;
  font-size: 18px;
  margin-right: 10px;
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background: rgba(51, 52, 72, 0.1);
  border-radius: 8px;
  color: #333448;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  font-size: 12px;
  font-weight: 500;
}

.tag:hover {
  background-color: #2e37a4;
  color: #fff;
}

.comment-by {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 10px;
}

.comments-list .comment-block p {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  text-align: justify;
  color: rgba(51, 52, 72, 0.5);
  font-weight: 500;
}

blockquote p {
  font-style: italic;
  font-weight: 400;
}

.grid-blog .blog-title {
  font-size: 16px;
  margin: 0 0 10px;
}

.grid-blog .blog-title a {
  color: #333448;
  font-size: 16px;
  font-weight: 500;
}

.grid-blog .blog-content p {
  font-size: 14px;
  margin: 0 0 15px;
  color: rgba(51, 52, 72, 0.5);
}

.grid-blog .blog-info {
  border: 0;
  border-top: 1px solid #eaeaea;
  margin-bottom: 0;
  padding: 12px 0;
}

.grid-blog .blog-image {
  margin-bottom: 20px;
  border-radius: 16px;
}

.grid-blog .read-more {
  font-size: 14px;
  margin-bottom: 0px;
  color: #2e37a4;
  font-weight: 500;
}

.product-thumbnail {
  position: relative;
  margin-bottom: 0;
  margin-top: 10px;
}

.product-remove {
  position: absolute;
  top: -10px;
  right: -6px;
  color: #f06060;
  cursor: pointer;
}

.bootstrap-tagsinput {
  width: 100%;
  height: auto;
  box-shadow: inherit;
  border-radius: 8px;
  line-height: 30px;
}

.bootstrap-tagsinput .badge [data-role="remove"]:hover {
  box-shadow: inherit;
  color: #f00;
}

.blog-views {
  position: absolute;
  top: 20px;
  left: 15px;
  background: #f5f6fa;
  border-radius: 6px;
  padding: 4px 10px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  font-size: 12px;
}

.blog-views h5 {
  color: #2e37a4;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

.view-blog-list.blog-views {
  bottom: 20px;
  top: inherit;
}

.blog-views li {
  color: #2e37a4;
  font-weight: 600;
}

.blog-views li:first-child {
  margin-right: 10px;
}

.blog-grp-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 25px;
}

.blog-img-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-blk-blog h4 a {
  font-size: 15px;
  font-weight: 600;
  color: #333448;
}

.content-blk-blog h4 {
  margin-bottom: 0;
}

.content-blk-blog h5 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  color: rgba(51, 53, 72, 0.5);
}

.blog-grp-blk span {
  color: #2e37a4;
  font-size: 12px;
  font-weight: 500;
}

.blog-grp-blk img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

/*-----------------
	32. Blog View
-----------------------*/

.blog-view .blog-title {
  font-size: 18px;
  font-weight: 500;
}

.blog-view .blog-info {
  border: 0 none;
  margin-bottom: 20px;
  padding: 0;
}

.social-share {
  float: left;
  list-style: none;
  margin: 5px 0 0;
  padding: 0;
}

.social-share>li {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: center;
}

.social-share>li:first-child {
  margin-left: 0;
}

.social-share>li>a {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 8px;
  color: #009efb;
  font-size: 22px;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.social-share>li>a:hover {
  background-color: #2e37a4;
  border-color: #2e37a4;
}

.social-share>li>a:hover img {
  filter: invert(1) brightness(100);
}

.share-blogs a {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 8px;
  color: #009efb;
  font-size: 22px;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.blog-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.widget h3 {
  color: #333448;
  font-size: 18px;
  margin: 0 0 20px;
}

.blog-reply>a {
  color: #2e37a4;
  font-size: 12px;
  font-weight: 500;
}

.blog-date {
  color: #999;
  font-size: 12px;
}

.comments-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comments-list li {
  clear: both;
  padding: 10px 0 0 50px;
}

.comments-list li .comment {
  margin-bottom: 30px;
}

.comments-list li .comment-author {
  margin-left: -50px;
  position: absolute;
}

.comments-list li img.avatar {
  height: 40px;
  width: 40px;
  border-radius: 58px;
}

.blog-author-name {
  color: #009efb;
  font-size: 18px;
}

.text-red {
  color: #f00;
}

.new-comment label {
  font-size: 14px;
  font-weight: 500;
}

.new-comment .form-group {
  margin-bottom: 20px;
}

.comment-submit .btn {
  background-color: #009efb;
  border-color: #009efb;
  border-radius: 0;
  font-size: 18px;
  padding: 8px 26px;
  color: #fff;
}

.about-author-img {
  background-color: #fff;
  height: 120px;
  overflow: hidden;
  position: absolute;
  width: 120px;
}

.author-details {
  margin-left: 145px;
}

.about-author {
  min-height: 120px;
}

.author-details .blog-author-name {
  display: inline-block;
  margin-bottom: 10px;
}

.blog-navigation {
  text-align: right;
}

.read-blks a,
.date-blks li a {
  color: rgba(51, 52, 72, 0.5);
  font-weight: 600;
}

.authr-blog-group h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.authr-blog-group p {
  color: rgba(51, 52, 72, 0.5);
  font-size: 12px;
  margin-bottom: 20px;
}

.authr-blog-group span {
  font-size: 12px;
  font-weight: 500;
  color: rgba(51, 53, 72, 0.5);
}

.social-blk {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.social-blk li {
  padding: 0px 10px;
}

.social-blk li a:hover img {
  filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(50%) contrast(340%);
}

.week-list {
  color: rgba(51, 53, 72, 0.5);
  font-size: 10px;
  font-weight: 500;
}

.week-group h5 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}

.share-blogs span {
  color: rgba(51, 52, 72, 0.5);
  font-size: 12px;
  font-weight: 600;
}

.list-add-blogs {
  margin-top: 25px;
}

.list-add-blogs ul li {
  background: rgba(51, 52, 72, 0.1);
  border-radius: 8px;
  padding: 5px 10px;
  margin-right: 10px;
  color: #333448;
  font-size: 12px;
  font-weight: 500;
}

.list-add-blogs ul li:hover {
  background: rgba(51, 52, 72, 0.3);
}

.list-add-blogs ul li:last-child {
  margin-right: 00px;
}

.relat-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 2px solid rgba(46, 55, 164, 0.05);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.relat-head h5 {
  margin-bottom: 0;
}

.relat-head a {
  font-weight: 500;
  font-size: 14px;
  color: #2e37a4;
}

.date-posts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.date-posts h5 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}

.date-posts span {
  color: rgba(51, 52, 72, 0.5);
  font-weight: 500;
  font-size: 12px;
}

/*-----------------
	33. UI Kit
-----------------------*/

.pagination-box .pagination {
  margin-top: 0;
}

.tab-content {
  padding-top: 20px;
}

/*-----------------
	34. Error
-----------------------*/

.error-pages {
  background: #fff;
}

.error-box {
  border-radius: 5px;
  line-height: 1;
  margin: 0 auto;
  max-width: 620px;
  padding: 50px 30px 55px;
  text-align: center;
  width: 100%;
}

.error-wrapper {
  margin: 0;
  color: #4f5155;
  -moz-box-align: center;
  -moz-box-pack: center;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.error-box h3 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.error-box p {
  margin-bottom: 40px;
  color: rgba(51, 52, 72, 0.5);
}

.error-box img {
  margin-bottom: 50px;
}

.error-box .btn {
  text-transform: capitalize;
  min-width: 179px;
  padding: 10px 15px;
  border-radius: 12px;
}

/*-----------------
	35. Lock Screen
-----------------------*/

.lock-user {
  margin-bottom: 20px;
}

.lock-user img {
  margin-bottom: 15px;
  width: 100px;
}

/*-----------------
	36. Voice call
-----------------------*/

.voice-call-avatar {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.voice-call-avatar .call-avatar {
  margin: 15px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3px;
  background-color: #fff;
}

.call-duration {
  display: inline-block;
  font-size: 30px;
  margin-top: 4px;
  position: absolute;
  left: 0;
}

.voice-call-avatar .call-timing-count {
  padding: 5px;
}

.voice-call-avatar .username {
  font-size: 18px;
  text-transform: uppercase;
}

.call-icons {
  text-align: center;
  position: relative;
}

.call-icons .call-items {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.call-icons .call-items .call-item {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

.call-icons .call-items .call-item:last-child {
  margin-right: 0;
}

.call-icons .call-items .call-item a {
  color: #777;
  border: 1px solid #ddd;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  display: inline-block;
  font-size: 20px;
}

.call-icons .call-items .call-item a i {
  width: 18px;
  height: 18px;
}

.user-video {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.user-video img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.user-video video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.my-video {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
}

.my-video ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.my-video ul li {
  float: left;
  width: 120px;
  margin-right: 10px;
}

.my-video ul li img {
  border: 3px solid #fff;
  border-radius: 6px;
}

.end-call {
  position: absolute;
  top: 7px;
  right: 0;
}

.end-call a {
  color: #fff;
  border-radius: 50px;
  display: inline-block;
  background-color: #f06060;
  padding: 8px 25px;
  text-transform: uppercase;
}

.call-users {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
}

.call-users ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.call-users ul li {
  float: left;
  width: 80px;
  margin-left: 10px;
}

.call-users ul li img {
  border-radius: 6px;
  padding: 2px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.call-mute {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  border-radius: 6px;
  font-size: 30px;
  color: #fff;
  display: none;
  top: 0;
  border: 3px solid transparent;
}

.call-users ul li a:hover .call-mute {
  display: block;
}

.call-details {
  margin: 10px 0 0px;
  display: flex;
}

.call-info {
  margin-left: 10px;
  width: 100%;
}

.call-user-details,
.call-timing {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.call-description {
  white-space: nowrap;
  vertical-align: bottom;
}

.call-timing {
  color: #a0a0a2;
  display: flex;
  font-size: 14px;
  margin-top: 1px;
  overflow: hidden;
  white-space: pre;
}

/*-----------------
	37. Video Call
-----------------------*/

.content-full {
  height: 100%;
  position: relative;
  width: 100%;
}

.video-window .fixed-header {
  padding: 0;
  border: 0;
}

.video-window .fixed-header .nav>li>a {
  padding: 19px 15px;
}

/*-----------------
	38. Incoming Call
-----------------------*/

.incoming-btns {
  margin-top: 20px;
}

.modal.custom-modal {
  background-color: rgba(0, 0, 0, 0.66);
}

.custom-modal .modal-content {
  border: 0;
  background-color: #fff;
}

.custom-modal .profile-widget {
  margin-bottom: 0;
}

.modal.custom-modal.modal-bg {
  background-color: rgba(0, 0, 0, 0.66);
}

.custom-modal.modal-bg .modal-content {
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
}

.custom-modal .modal-body {
  padding: 30px;
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
}

/*-----------------
	39. Notification settings
-----------------------*/

.notification-list .list-group-item {
  padding: 15px;
}

.notification-list .status-toggle {
  float: right;
}

.invoice-load-btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.invoice-load-btn .btn {
  font-weight: 700;
  font-size: 14px;
  color: #009efb;
  min-width: 170px;
  padding: 10px 15px;
  background: #ffffff;
  border: 2px solid #009efb;
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.invoice-load-btn .btn:hover span {
  border: 3px solid #ffffff7a;
  border-right-color: #fff;
}

.invoice-load-btn .btn:hover {
  background: #009efb;
  color: #fff;
}

.invoice-load-btn .btn span {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 8px;
  vertical-align: 0;
  border: 3px solid #9a55ff21;
  border-right-color: #009efb;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.invoices-grid-card .card-footer .badge {
  border-radius: 6px;
  font-size: 13px;
  font-weight: 400;
  padding: 7px 18px;
}

.invoices-grid-card .bg-success-dark {
  background: #008f64;
}

.invoices-grid-card .bg-success-dark {
  background: #008f64;
}

.invoices-grid-card .bg-danger-dark {
  background: #ff0000;
}

.invoices-grid-card .bg-secondary-dark {
  background: #009efb;
}

.invoices-grid-card .bg-primary-dark {
  background: #2196f3;
}

.invoices-grid-card .text-sm {
  font-size: 14px;
}

.invoices-page-header {
  background: #ffffff;
  border-radius: 10px;
  padding: 12px;
}

.page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}

.invoices-page-header .invoices-breadcrumb-item i {
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 10px;
  margin-right: 10px;
  background: #2e37a4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.invoices-create-btn .invoices-preview-link {
  font-weight: 500;
  color: #2e37a4;
  margin-right: 30px;
  font-size: 15px;
}

.invoices-create-btn .delete-invoice-btn {
  background: #6c757d;
  margin-right: 10px;
}

.invoices-create-btn .btn {
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  border-radius: 8px;
  padding: 10px 0px;
  min-width: 160px;
  position: relative;
}

.check:checked+.checktoggle {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.payment-details {
  width: 266px;
  border-radius: 4px;
  padding: 15px;
  background: #2e37a4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-details p {
  color: #fff;
  max-width: 140px;
  font-weight: 400;
  margin-bottom: 0;
}

.invoices-create-btn .save-invoice-btn {
  background: #2e37a4;
}

.invoices-add-card {
  background: #ffffff;
  border-radius: 10px;
}

.invoices-main-form {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
}

.invoices-main-form label {
  font-size: 14px;
}

.multipleSelection {
  position: relative;
  margin-bottom: 20px;
}

.invoices-form .multipleSelection .selectBox {
  border: 2px solid #2e37a4;
  border-radius: 6px;
}

.multipleSelection #checkBoxes-one {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  margin-top: 10px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #f2ecff;
  box-shadow: 0px 4px 44px rgb(231 234 252 / 75%);
  border-radius: 6px;
}

.invoice-details-title {
  font-weight: 500;
  font-size: 18px;
  color: #1b2559;
  margin-bottom: 0.5rem;
}

.invoice-details-box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.invoice-inner-head {
  padding: 26px;
  border-bottom: 1px solid #e5e5e5;
}

.invoice-inner-head span {
  color: #1b2559;
  font-size: 14px;
}

.invoice-inner-head span a {
  font-weight: 500;
  color: #2e37a4;
}

.invoice-inner-footer {
  padding: 0;
}

.invoice-inner-date {
  padding: 26px;
  border-right: 1px solid #e5e5e5;
}

.payment-details {
  width: 266px;
  border-radius: 4px;
  padding: 15px;
  background: #2e37a4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-details p {
  color: #fff;
  max-width: 140px;
  font-weight: 400;
  margin-bottom: 0;
}

.payment-details a {
  font-size: 26px;
  color: #fff;
}

.invoice-inner-footer .form-control {
  position: absolute;
  color: #009efb;
  background: transparent;
  border: 0;
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0 10px;
}

.invoice-inner-footer span {
  font-weight: 500;
  color: #1b2559;
  position: relative;
}

.invoice-inner-datepic {
  border: 0;
  padding-left: 0;
}

.invoices-form .form-control {
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

.invoice-info {
  margin-bottom: 20px;
}

.invoice-item .customer-text {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
}

.invoice-item .customer-text a {
  font-size: 14px;
  color: #009efb;
  margin-left: 12px;
}

.invoice-item .invoice-details-two {
  text-align: left;
  color: #000;
  float: left;
}

.invoice-add-table h4 {
  font-weight: 600;
  font-size: 18px;
  color: #2e37a4;
  margin-bottom: 30px;
}

.invoice-add-table .add-btns {
  color: #2e37a4;
}

.invoice-add-table .copy-btn {
  color: #c4c4c4;
}

.invoice-add-table .remove-btn {
  color: #ff0000;
}

.invoice-fields .field-title {
  font-weight: 600;
  font-size: 16px;
  color: #1b2559;
  margin-bottom: 15px;
}

.field-box {
  padding: 15px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.field-box p {
  font-weight: 500;
  font-size: 14px;
  color: #1b2559;
  margin-bottom: 10px;
}

.field-box .btn {
  min-width: 266px;
  border-radius: 4px;
  padding: 30px 0;
}

.invoice-faq .faq-tab {
  padding-top: 10px;
}

.faq-tab .panel-title {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 0px;
  position: relative;
  font-size: 14px;
}

.faq-tab .panel-title a {
  color: #c4c4c4;
}

.faq-tab .panel-title a.collapsed::after {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 20px;
  top: 10px;
  color: #f09e9e;
  font-weight: 600;
  font-size: 14px;
}

.faq-tab .panel-title a:not(.collapsed)::after {
  color: #ff0000;
}

.invoice-total-card .invoice-total-title {
  font-weight: 600;
  font-size: 16px;
  color: #1b2559;
  margin-bottom: 15px;
}

.invoice-total-box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.invoice-total-inner {
  padding: 15px;
}

.invoice-total-box p {
  color: #1b2559;
  margin-bottom: 20px;
  position: relative;
  text-align: initial;
  font-size: 14px;
}

.invoice-total-box p span {
  float: right;
}

.invoice-total-box .add-links {
  font-weight: 500;
  color: #009efb;
  margin-bottom: 20px;
  display: block;
}

.invoice-total-box .service-trash-one {
  margin-top: 10px;
  color: #1b2559;
}

.invoice-total-footer {
  border-top: 1px solid #e5e5e5;
  padding: 15px;
}

.invoice-total-footer h4 {
  font-size: 16px;
  margin-bottom: 0;
  color: #2e37a4;
  text-align: initial;
}

.invoice-total-footer h4 span {
  float: right;
}

.upload-sign {
  float: right;
  margin-top: 20px;
}

.service-upload {
  border: 2px dashed #e5e5e5;
  text-align: center;
  padding: 30px 0;
  background-color: #fff;
  position: relative;
  width: 330px;
}

.service-upload span {
  font-size: 15px;
  color: #858585;
  display: block;
}

.service-upload input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-sign .btn {
  border-radius: 8px;
  min-width: 198px;
  padding: 10px 0;
}

.invoice-total-box .service-amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.invoice-total-box .service-trash,
.invoice-total-box .service-trash1 {
  margin-bottom: 10px;
  color: #1b2559;
}

#show-invoices {
  display: none;
}

.invoice-total-box .service-trash i {
  color: #ec1361;
}

.check:checked+.checktoggles {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.check:checked+.checktoggles:after {
  background-color: #fff;
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
}

.checktoggles:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}

.checktoggles {
  background-color: #c4c4c4;
  border: 1px solid #c4c4c4;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0;
  height: 15px;
  margin-left: 10px;
  top: 3px;
  margin-bottom: 0;
  position: absolute;
  width: 30px;
  display: inline-block;
}

.invoice-total-box .add-links1,
.add-links-one {
  font-weight: 500;
  color: #2e37a4;
  margin-bottom: 20px;
  display: block;
  font-size: 14px;
}

.invoice-add-table th {
  background: #f8f9fa;
  padding: 15px;
  font-size: 14px;
}

.table> :not(:first-child) {
  border-top: 0;
}

td.add-remove {
  padding: 20px;
}

.add-remove i {
  font-size: 18px;
  cursor: pointer;
}

.bank-details .modal-content {
  border: 1px solid #f2ecff;
  border-radius: 6px;
}

.bank-details .modal-header {
  border: 0;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #e5e5e5;
}

.bank-details .close {
  background: transparent;
  border: 0;
  color: #1b2559;
  font-size: 28px;
  line-height: normal;
  top: 20px;
  width: auto;
  height: auto;
  right: 20px;
}

.bank-details .modal-body {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.bank-details .bank-inner-details .form-control {
  height: 50px;
}

.bank-details .modal-footer {
  justify-content: end;
  padding: 30px;
}

.bank-details .modal-footer>* {
  margin: 0;
}

.bank-details-btn .bank-cancel-btn {
  background: #1b2559;
}

.bank-details-btn .bank-save-btn {
  background: #2e37a4;
}

.bank-details-btn .btn {
  min-width: 160px;
  border-radius: 8px;
  padding: 10px 0;
  color: #fff;
}

.invoice-inner-footer .form-control {
  position: relative;
  color: #009efb;
  background: transparent;
  border: 0;
  display: inline-block;
  width: 120px;
  height: auto;
  padding: 0 10px;
}

.invoice-inner-footer span {
  font-weight: 500;
  color: #1b2559;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.invoice-inner-date {
  padding: 8px 15px;
  border-right: 1px solid #e5e5e5;
}

.invoice-info-card {
  border-radius: 14px;
}

.invoice-item-one {
  border-bottom: 1px solid rgba(231, 234, 252, 0.75);
}

.faq-tab .panel-title a:hover {
  color: #1b2559;
}

.faq-tab .panel-title a:not(.collapsed)::after {
  color: #ff0000;
}

.faq-tab .panel-title a::after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 20px;
  top: 10px;
  color: #f09e9e;
  font-weight: 600;
  font-size: 14px;
}

.faq-tab .panel-body textarea {
  min-height: 100px;
  border: 0;
  border-radius: 0 0 4px 4px;
}

.faq-tab .panel-body {
  padding: 10px 20px;
  border: 1px solid #e5e5e5;
}

.inovices-month-info {
  padding-top: 50px;
}

.invoice-add-table {
  padding-top: 20px;
  padding-bottom: 30px;
}

.invoice-item .invoice-logo {
  margin-bottom: 30px;
}

.invoice-item .invoice-logo img {
  width: auto;
  max-height: 52px;
}

.invoice-item .invoice-text h2 {
  color: #272b41;
  font-size: 36px;
  font-weight: 600;
}

.invoice-item .invoice-details {
  text-align: right;
  font-weight: 400;
}

.invoice-item .invoice-details strong {
  color: #272b41;
}

.invoice-item .invoice-details-two {
  text-align: left;
  color: #000;
  font-size: 13px;
}

.invoice-item .invoice-text {
  padding-top: 42px;
  padding-bottom: 36px;
}

.invoice-item .invoice-text h2 {
  font-weight: 400;
}

.invoice-info {
  margin-bottom: 20px;
}

.invoice-info p {
  margin-bottom: 0;
  color: rgba(51, 53, 72, 0.5);
  font-size: 14px;
}

.invoice-info.invoice-info2 {
  text-align: right;
}

.invoice-item .customer-text {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
}

.invoice-item .customer-text a {
  font-size: 14px;
  color: #2e37a4;
  margin-left: 12px;
}

.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
  color: #272b41;
  font-weight: 600;
  padding: 15px 20px;
  line-height: inherit;
}

.invoice-table tr td,
.invoice-table-two tr td {
  font-weight: 500;
}

.invoice-table-two {
  margin-bottom: 0;
}

.invoice-table-two tr th,
.invoice-table-two tr td {
  border-top: 0;
}

.invoice-table-two tr td {
  text-align: right;
}

.invoice-info h5 {
  font-size: 16px;
  font-weight: 500;
}

.other-info {
  margin-top: 10px;
}

.file {
  visibility: hidden;
  position: absolute;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.font-size-14 {
  font-size: 14px;
}

.invoice-info-card {
  border-radius: 14px;
}

.invoice-item-one {
  border-bottom: 1px solid rgba(231, 234, 252, 0.75);
}

.invoice-item-one .invoice-info {
  margin-bottom: 30px;
  text-align: right;
}

.customer-text-one {
  font-size: 16px;
  color: #1b2559;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.invoice-name {
  color: #1b2559;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.invoice-item-one .invoice-details {
  color: #1b2559;
  font-weight: 400;
}

.invoice-head h2 {
  font-weight: 600;
  font-size: 22px;
  color: #1b2559;
  text-transform: uppercase;
  margin-bottom: 0;
}

.invoice-head {
  margin-bottom: 30px;
}

.invoice-head p {
  font-weight: 400;
  font-size: 16px;
  color: #1b2559;
  margin-bottom: 0;
}

.invoice-item-two {
  padding-top: 30px;
  padding-bottom: 30px;
}

.invoice-item-two .invoice-details {
  color: #1b2559;
  font-weight: 400;
  width: 100%;
}

.invoice-item-box {
  background: #55ddff30;
  border-radius: 5px;
  padding: 15px;
  width: 200px;
  float: right;
  margin-top: 20px;
}

.invoice-item-box p {
  color: #1b2559;
  font-weight: 400;
  margin-bottom: 10px;
}

.invoice-issues-box {
  background: #2e37a4;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  margin-bottom: 30px;
}

.invoice-issues-date {
  padding-top: 20px;
  padding-bottom: 20px;
}

.invoice-issues-date p {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0;
}

.invoice-table-wrap {
  margin-bottom: 30px;
}

.invoice-table>thead>tr>th {
  background: #f5f7ff;
  border-top: 0;
  font-weight: 500;
  font-size: 14px;
  color: #1b2559;
}

.invoice-table>tbody>tr>td {
  font-weight: 400;
  color: #1b2559;
  padding: 15px 20px;
  font-size: 14px;
}

.invoice-terms {
  margin-bottom: 30px;
}

.invoice-total-inner input[type="checkbox"] {
  display: none;
}

.invoice-terms h6 {
  font-weight: 500;
  font-size: 15px;
  color: #1b2559;
  margin-bottom: 6px;
}

.invoice-terms p {
  font-weight: 400;
  font-size: 14px;
  color: #8f9bba;
}

.invoice-sign {
  padding-top: 30px;
}

.invoice-sign span {
  color: #1b2559;
  margin-top: 18px;
}

.invoice-item-bg {
  background: #2e37a4;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.invoice-circle-img {
  position: relative;
}

.invoice-circle-img .invoice-circle1 {
  position: absolute;
  left: 0;
  opacity: 0.1;
}

.invoice-circle-img .invoice-circle2 {
  position: absolute;
  right: 0;
  opacity: 0.1;
}

.invoice-item-bg .invoice-info {
  border-right: 1px solid #fff;
  padding: 30px;
  margin-top: 20px;
  text-align: initial;
  display: flow-root;
}

.invoice-payment-box {
  text-align: left;
}

.invoice-item-bg .customer-text-one {
  color: #fff;
}

.invoice-item-bg .invoice-name {
  color: #fff;
}

.invoice-item-bg .invoice-details-two {
  color: #fff;
}

.invoice-item-bg .invoice-info-one p {
  color: #fff;
  margin-bottom: 10px;
}

.invoice-sign-box {
  margin-top: 30px;
  text-align: initial;
}

.invoice-payment-box h4 {
  font-weight: 600;
  font-size: 16px;
  color: #1b2559;
  margin-bottom: 16px;
}

.invoice-payment-box .payment-details {
  background: transparent;
  border: 2px dashed #009efb;
}

.invoice-payment-box .payment-details p {
  font-weight: 600;
  font-size: 15px;
  color: #1b2559;
}

.invoices-preview .modal-body {
  padding: 0;
}

.settings-menu {
  margin-bottom: 30px;
}

.settings-menu ul {
  display: block;
  padding: 0;
  list-style: none;
}

.settings-menu ul li a.active {
  color: #2e37a4 !important;
  border: 0;
}

.settings-menu ul li {
  margin-bottom: 15px;
}

.settings-menu ul li:last-child {
  margin-bottom: 0;
}

.settings-menu ul li a {
  color: #1b2559;
  padding: 0;
  border: 0 !important;
  display: inline-block;
}

.invoices-upload-btn {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 50px;
}

.invoices-upload-btn .upload {
  position: absolute;
  background: #2e37a4;
  border-radius: 4px;
  right: 6px;
  top: 8px;
  width: 136px;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.invoices-upload-btn .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}

.invoice-setting-btn .cancel-btn {
  background: #6c757d;
}

.invoice-setting-btn .btn {
  min-width: 160px;
  padding: 10px 0;
  color: #fff;
  border-radius: 8px;
  border: 0;
}

.invoice-setting-btn {
  display: flex;
  align-items: center;
  /* justify-content:flex-end; */
}

.paid-continue-btn {
  background-color: #2e37a4;
  border: 1px solid #2e37a4;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 20px;
  text-align: center;
}

.paid-cancel-btn {
  background-color: #fff;
  border: 1px solid #2e37a4;
  border-radius: 5px;
  color: #2e37a4;
  display: block;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 20px;
  text-align: center;
}

.invoices-page-header .invoices-breadcrumb-item a {
  font-weight: 500;
  color: #2e37a4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.page-header {
  margin-bottom: 1.875rem;
}

.invoices-table.custom-table tbody tr {
  box-shadow: none;
}

.invoices-table.custom-table tbody tr td {
  vertical-align: middle;
}

.invoices-table.custom-table thead tr th {
  color: #333448;
}

.add-table-invoice {
  background: rgba(46, 55, 164, 0.05);
  border-radius: 12px;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.add-table-invoice i {
  color: #2e37a4;
}

.grand-totals tr td:first-child {
  width: 75%;
}

.custom-table .bold-total td {
  font-weight: 600;
}

.custom-table .bold-total .form-control {
  font-weight: 600;
}

.invoice-clinic li {
  color: rgba(51, 53, 72, 0.5);
  font-size: 14px;
  font-weight: 500;
}

.invoice-details h3 {
  font-size: 14px;
  font-weight: 700;
}

.invoice-details h4 {
  font-size: 14px;
  font-weight: 600;
}

.invoice-details h4 span {
  font-weight: 500;
  color: rgba(51, 53, 72, 0.5);
}

.bold-total-invoice h5 {
  font-size: 22px;
  color: #333448;
  font-weight: 600;
}

.total-fonts tr td {
  color: rgba(51, 53, 72, 0.5);
  font-weight: 500;
}

.invoice-head-clinic {
  border-bottom: 2px solid rgba(46, 55, 164, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.invoice-counts h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.invoice-counts h4 span {
  font-size: 15px;
  font-weight: 500;
  color: rgb(51 53 72 / 50%);
}

.invoice-counts a {
  padding: 3px 23px;
  font-size: 15px;
  border-radius: 8px;
  margin-left: 15px;
}

/*-----------------
	40. Chat Sidebar
-----------------------*/

.chat-sidebar {
  background-color: #fff;
  border-left: 1px solid #eaeaea;
  width: 300px;
}

.chat-sidebar .chat-contents {
  background-color: #fff;
}

.chat-sidebar .chat-left .chat-content {
  border: 0;
  padding: 0;
  border-radius: 0 !important;
  background-color: transparent;
  max-width: 100%;
}

.chat-sidebar .chat-left .chat-body {
  margin-left: 50px;
  padding-right: 0;
}

.chat-sidebar .chat-date {
  background-color: #fff;
}

.chat-sidebar .chats {
  padding: 15px 15px 30px;
}

.chat-sidebar .avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.chat-sidebar .chat.chat-left {
  margin-bottom: 20px;
}

.chat-sidebar .chat.chat-left:last-child {
  margin-bottom: 0;
}

.chat-sidebar .chat-left .chat-time {
  color: #888;
  display: inline-block;
  font-size: 11px;
}

.chat-sidebar .chat-content>p {
  font-size: 13px;
  margin-bottom: 0;
}

.chat-sidebar.opened {
  margin-right: 0;
}

.ck.ck-editor__editable_inline>:last-child {
  min-height: 150px !important;
}

/*-----------------
	41. Gallery
-----------------------*/

span.rti--tag {
  font-size: 12px;
  font-weight: 550;
  border-radius: 0px;
  background: #E4E4E4 !important;
  padding: 6px 5px;
}

.lg-backdrop {
  z-index: 1041;
}

#lightgallery .img-thumbnail {
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
}

/*-----------------
   42. Settings
-----------------------*/

.settings-menu-links .nav-tabs.menu-tabs {
  border: none;
  margin-bottom: 20px;
  margin-top: 20px;
}

.nav-tabs.menu-tabs .nav-item {
  padding-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}

.nav-tabs.menu-tabs .nav-item.active .nav-link {
  color: #fff;
  background: #2e37a4;
}

.nav-tabs.menu-tabs .nav-link {
  position: relative;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  background: rgb(46 55 164 / 53%);
}

.card .card-header .card-title {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}

.settings-form {
  margin-top: 20px;
}

.settings-label {
  margin-bottom: 10px;
  font-size: 13px;
}

.settings-btn {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 46px;
}

.settings-btn .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}

.settings-btn .upload {
  position: absolute;
  z-index: 1;
  background: #2e37a4;
  border-radius: 5px;
  right: 2px;
  top: 1.5px;
  width: 41px;
  height: 41px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.settings-size {
  font-size: 13px;
  color: #131523;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
}

.settings-size span {
  font-weight: 700;
}

.upload-images {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  display: flex;
  margin-top: 30px;
  width: 196px;
  height: 78px;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.upload-images img {
  max-height: 64px;
  width: auto;
}

.upload-images .btn-icon {
  color: #00d3c7;
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  padding: 0;
  width: 20px;
  height: 20px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  right: 0px;
  top: 0px;
  justify-content: center;
  -webkit-justify-content: center;
}

.invoice-load-btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.invoice-load-btn .btn {
  font-weight: 700;
  font-size: 14px;
  color: #00d3c7;
  min-width: 170px;
  padding: 10px 15px;
  background: #ffffff;
  border: 2px solid #00d3c7;
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.invoice-load-btn .btn span {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 8px;
  vertical-align: 0;
  border: 3px solid rgb(0 211 199 / 57%);
  border-right-color: #00d3c7;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.upload-size {
  width: 82px;
  height: 82px;
}

.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.checktoggle {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0 !important;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
}

.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  width: 16px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}

.check:checked+.checktoggle {
  background-color: #2e37a4;
  border: 1px solid #2e37a4;
}

.star-red {
  color: #ff0000;
}

form .btn-gradient-primary {
  font-size: 14px;
  padding: 8px 40px;
  color: #fff;
}

form .btn-secondary {
  font-size: 14px;
  padding: 7px 30px;
  color: #fff;
}

.check:checked+.checktoggle:after {
  background-color: #fff;
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
}

.card-header {
  padding: 15px;
}

.settings-form .custom_radio {
  color: #000;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.settings-form .custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #2e37a4;
  border-radius: 50%;
}

.custom_radio input:checked~.checkmark::after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.settings-form .custom_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #2e37a4;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.settings-form .social-icon {
  width: 50px;
  height: 42px;
  margin-right: 6px;
  color: #fff;
  background: #2e37a4;
  border: 1px solid #2e37a4;
  border-radius: 5px;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 4px;
}

.settings-form .links-cont .social-icon::after {
  content: "";
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-bottom: 0;
  border-left: 0.2em solid transparent;
  margin-left: 4px;
}

.settings-form .trash {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
  width: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background-color: #2e37a4;
  color: #fff;
  position: relative;
  font-size: 16px;
  margin-left: 6px;
}

.add-links {
  background-color: #2e37a4;
  color: #fff !important;
  font-size: 13px;
  font-weight: 700;
  padding: 10px 26px;
  border-radius: 4px;
}

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}

.bootstrap-tagsinput input::placeholder {
  font-size: 14px;
}

.settings-form .bootstrap-tagsinput .tag {
  margin-right: 5px;
  margin-bottom: 0px;
  color: #000000;
  background: #e4e4e4 !important;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 24px;
}

.settings-form .bootstrap-tagsinput {
  display: block;
  color: #333;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: unset;
  min-height: 46px;
  padding-top: 4px;
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 4px;
}

.form-row.links-cont {
  margin-bottom: 0px;
}

textarea.form-control {
  resize: vertical;
}

textarea.form-control {
  height: auto;
}

.settings-form .form-group label {
  margin-bottom: 10px;
  font-size: 13px;
}

.card.invoices-grid-card .card-footer {
  background-color: #fff;
}

.card-block tr th,
.card-block tr td {
  font-size: 14px;
}

.modal-body label {
  font-size: 13px;
}

.invoice-settings-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.border-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.border-btn .btn {
  font-weight: 600;
  color: #828282;
  background-color: #fff;
  padding: 12px 10px;
  min-width: 120px;
  border: 1px solid #828282;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.invoice-settings-btn .btn {
  font-weight: 600;
  color: #fff;
  background-color: #2e37a4;
  padding: 12px 0;
  min-width: 167px;
  border: 1px solid #2e37a4;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.invoice-settings-btn .btn i {
  margin-right: 10px;
}

.css-nzukxv-control:hover {
  border-color: 2px solid #fff !important;
}

/* .local-forms .css-nzukxv-control:hover {
  color: none;
} */
.css-8x8mdc-control {
  background-color: 2px solid #fff !important;
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-width: 2px !important;
}

.css-b62m3t-container:focus-visible {
  outline: none;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.ant-picker:hover {
  border: 2px solid rgba(46, 55, 164, 0.1) !important;
}

/* Time Picker */

:where(.css-dev-only-do-not-override-xu9wm8).ant-picker {
  display: flex !important;
}

/*-----------------
	43. Responsive
-----------------------*/

@media only screen and (min-width: 991px) {
  #toggle_btn {
    color: #fff;
    float: left;
    font-size: 20px;
    line-height: 70px;
    padding: 0 10px;
  }

  .user-active .call-imgs {
    width: 100%;
  }

  .join-video.user-active {
    height: auto;
  }

  .single-user .call-imgs {
    width: 100%;
  }

  .mini-sidebar .header-left .logo span {
    display: none;
  }

  .mini-sidebar .header .header-left {
    padding: 0 5px;
    width: 60px;
  }

  .mini-sidebar .sidebar {
    width: 60px;
  }

  .mini-sidebar.expand-menu .sidebar {
    width: 230px;
  }

  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }

  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }

  .modal-open .mini-sidebar .sidebar {
    z-index: 1051;
  }

  .mini-sidebar .sidebar .sidebar-menu ul>li>a span {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }

  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul>li>a span {
    display: inline;
    opacity: 1;
  }

  .mini-sidebar .page-wrapper {
    margin-left: 60px;
  }

  .mini-sidebar .sidebar .sidebar-menu ul>li>a span.menu-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
  }

  .mini-sidebar .sidebar-menu>ul>li>a {
    padding: 12px 10px;
  }

  .mini-sidebar.expand-menu .sidebar-menu>ul>li>a {
    padding: 12px 20px;
  }
}

@media only screen and (min-width: 768px) {
  .modal-md {
    width: 500px;
  }

  .nav-tabs.nav-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs.nav-justified.nav-tabs-top>li>a,
  .nav-tabs.nav-justified.nav-tabs-top>li>a:hover,
  .nav-tabs.nav-justified.nav-tabs-top>li>a:focus {
    border-width: 2px 0 0 0;
  }

  .nav-tabs.nav-tabs-top>li {
    margin-bottom: 0;
  }

  .nav-tabs.nav-tabs-top>li>a,
  .nav-tabs.nav-tabs-top>li>a:hover,
  .nav-tabs.nav-tabs-top>li>a:focus {
    border-width: 2px 0 0 0;
  }

  .nav-tabs.nav-tabs-top>li+li>a {
    margin-left: 1px;
  }

  .nav-tabs.nav-tabs-top>li>a.active,
  .nav-tabs.nav-tabs-top>li>a.active:hover,
  .nav-tabs.nav-tabs-top>li>a.active:focus {
    border-top-color: #2e37a4;
  }

  .nav-tabs.nav-tabs-bottom>li {
    margin-bottom: -1px;
  }

  .nav-tabs.nav-tabs-bottom>li>a.active,
  .nav-tabs.nav-tabs-bottom>li>a.active:hover,
  .nav-tabs.nav-tabs-bottom>li>a.active:focus {
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: #009efb;
    background-color: transparent;
    transition: none 0s ease 0s;
    -moz-transition: none 0s ease 0s;
    -o-transition: none 0s ease 0s;
    -ms-transition: none 0s ease 0s;
    -webkit-transition: none 0s ease 0s;
  }

  .nav-tabs.nav-tabs-bottom>li {
    margin-bottom: -1px;
  }

  .nav-tabs.nav-tabs-bottom>li>a.active,
  .nav-tabs.nav-tabs-bottom>li>a.active:hover,
  .nav-tabs.nav-tabs-bottom>li>a.active:focus {
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: #2e37a4;
    background-color: transparent;
    transition: none 0s ease 0s;
    -moz-transition: none 0s ease 0s;
    -o-transition: none 0s ease 0s;
    -ms-transition: none 0s ease 0s;
    -webkit-transition: none 0s ease 0s;
  }

  .nav-tabs.nav-tabs-solid {
    background-color: #fafafa;
    border: 0;
  }

  .nav-tabs.nav-tabs-solid>li {
    margin-bottom: 0;
  }

  .nav-tabs.nav-tabs-solid>li>a {
    border-color: transparent;
  }

  .nav-tabs.nav-tabs-solid>li>a:hover,
  .nav-tabs.nav-tabs-solid>li>a:focus {
    background-color: #f5f5f5;
  }

  .nav-tabs.nav-tabs-solid>.open:not(.active)>a {
    background-color: #f5f5f5;
    border-color: transparent;
  }

  .nav-tabs-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs-justified.nav-tabs-top>li>a,
  .nav-tabs-justified.nav-tabs-top>li>a:hover,
  .nav-tabs-justified.nav-tabs-top>li>a:focus {
    border-width: 2px 0 0 0;
  }
}

@media only screen and (max-width: 1199.98px) {
  .invoices-view {
    width: 90%;
    float: right;
  }

  .invoices-tabs ul li {
    margin-right: 10px;
  }

  .invoices-tabs ul li a {
    font-size: 14px;
  }

  .login-wrap-bg {
    padding: 15px 50px 15px;
  }

  .login-wrapper .loginbox .login-right {
    padding: 30px;
  }

  .morning-user {
    padding: 30px 0px 30px 30px;
  }

  .morning-user h2 {
    font-size: 28px;
    font-weight: 600;
  }

  .border-right-bg:before {
    display: none;
  }

  .border-right-bg {
    margin-bottom: 10px;
  }

  .wallet-widget .circle-bar>div canvas {
    width: 140px !important;
    height: 140px !important;
  }

  .meeting .join-video {
    height: 320px;
  }

  .join-video.single-user {
    height: auto;
  }
}

@media only screen and (max-width: 991.98px) {
  .profile-rightbar {
    display: inline-block !important;
  }

  .chat-profile-view {
    display: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    right: 0;
    transform: translateX(0px);
    z-index: 1041;
    width: 300px;
    position: fixed;
    margin-right: -300px;
    display: table-cell;
    top: 0;
    padding-bottom: 60px;
    margin-top: 50px;
  }

  .message-view.chat-view {
    width: 100%;
  }

  .slide-nav .page-wrapper {
    left: 225px;
    margin-left: 0;
  }

  .chat-main-row {
    left: 0;
  }

  .header .header-left {
    position: absolute;
    width: 100%;
  }

  .mobile_btn {
    display: block;
  }

  .page-title-box {
    display: none;
  }

  .sidebar {
    margin-left: -225px;
    width: 225px;
  }

  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .slide-nav .sidebar {
    margin-left: 0;
  }

  a.mobile_btn {
    color: #fff;
    font-size: 24px;
    height: 70px;
    left: 0;
    line-height: 70px;
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 60px;
    z-index: 10;
  }

  .slide-nav .page-wrapper {
    left: 225px;
    margin-left: 0;
  }

  .invoices-view {
    width: 100%;
    float: none;
  }

  #toggle_btn {
    display: none;
  }

  .sidebar {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .page-wrapper {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  /* invoice */
  .app-listing li {
    width: 49%;
    display: inline-block;
  }

  .app-listing {
    display: inline-block;
  }

  .multipleSelection #checkBoxes,
  .SortBy #checkBox {
    width: 100%;
  }

  .inovices-month-info {
    padding-top: 20px;
  }

  .invoice-inner-date {
    padding: 10px;
  }

  .invoice-inner-date.invoice-inner-datepic {
    padding: 10px 0;
  }

  .field-box .btn {
    min-width: auto;
    border-radius: 4px;
    padding: 10px;
  }

  .invoice-inner-head {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
  }

  .invoice-circle-img {
    display: none;
  }

  .invoice-item-bg .invoice-info {
    border-right: 1px solid #fff;
    padding: 10px 30px;
    margin-top: 20px;
  }

  .invoice-item-bg .invoice-info {
    border-right: 1px solid #fff;
    padding: 10px 30px;
    margin-top: 0;
  }

  .invoice-info {
    margin-bottom: 0;
  }

  .payment-details {
    width: auto;
  }

  .invoices-preview .modal-dialog {
    max-width: 700px;
  }

  .invoice-item-one .invoice-info {
    text-align: left;
  }

  .invoice-head h2 {
    font-size: 16px;
  }

  .invoice-head p {
    font-size: 14px;
  }

  .invoice-payment-box .payment-details {
    margin-bottom: 20px;
  }

  .invoice-faq .faq-tab {
    padding-bottom: 10px;
  }

  .service-upload {
    padding: 30px 0;
    width: auto;
  }

  .invoice-inner-date.invoice-inner-datepic {
    padding: 10px;
  }

  .bank-details-btn .btn {
    min-width: auto;
    padding: 10px;
  }

  .invoice-info-card .invoice-item-one .invoice-info {
    text-align: right;
  }

  .login-wrap {
    display: none;
  }

  .account-logo,
  .login-wrapper .loginbox .login-right h2,
  .login-wrapper .loginbox .login-right .forgotpass,
  .login-btn {
    margin-bottom: 25px;
  }

  .login-wrapper .loginbox .login-right h2 {
    font-size: 20px;
  }

  .error-box h3 {
    font-size: 26px;
  }

  .mob-view {
    display: none;
  }

  .logo {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }

  .morning-user {
    padding: 25px 0px 25px 25px;
  }

  .morning-user h2 {
    font-size: 26px;
  }

  .user-menu.nav li {
    margin-right: 6px;
  }

  .profile-user-img img {
    width: 60px;
  }

  .profile-edit-icon img {
    width: 15px;
  }

  .names-profiles h4 {
    font-size: 14px;
  }

  .names-profiles h5 {
    font-size: 12px;
  }

  .doctor-follows h5 {
    font-size: 12px;
  }

  .doctor-follows h4 {
    font-size: 16px;
  }

  .follow-btn-group .btn-info {
    min-width: 90px;
    font-size: 12px;
    padding: 4px 15px;
  }

  .meeting .join-video {
    height: 320px;
  }

  .join-video.single-user {
    height: auto;
  }
}

@media only screen and (max-width: 767.98px) {
  .table .invoice-link {
    font-size: 14px;
  }

  .profile-info-left {
    border-right: none;
    /*border-bottom: 2px dashed #ccc;*/
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }

  .notifications {
    right: -48px;
  }

  .notifications:before,
  .notifications:after {
    right: 60px;
  }

  .modal-body.card-box {
    background-color: #fff;
    border: none;
    border-radius: inherit;
    box-shadow: unset;
    margin-bottom: 0;
    padding: 15px;
  }

  .roles-menu {
    margin-bottom: 20px;
  }

  .left-action {
    text-align: center;
    margin-bottom: 15px;
  }

  .right-action {
    text-align: center;
  }

  .top-action-left .float-left {
    float: none !important;
  }

  .top-action-left .btn-group {
    margin-bottom: 15px;
  }

  .top-action-right {
    text-align: center;
  }

  .top-action-right a.btn.btn-white {
    margin-bottom: 15px;
  }

  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%;
  }

  .nav-tabs.nav-justified {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs.nav-justified>li>a.active,
  .nav-tabs.nav-justified>li>a.active:hover,
  .nav-tabs.nav-justified>li>a.active:focus {
    border-color: transparent transparent transparent #009efb;
    border-left-width: 2px;
  }

  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #fff;
    padding: 5px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0;
  }

  .nav-tabs>li>a {
    border-width: 2px;
    border-left-color: transparent;
  }

  .nav-tabs .nav-link {
    border-width: 2px;
  }

  .nav-tabs>li>a:hover,
  .nav-tabs>li>a:focus {
    background-color: #fafafa;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs>li>a.active,
  .nav-tabs>li>a.active:hover,
  .nav-tabs>li>a.active:focus {
    background-color: #f5f5f5;
    border-color: transparent transparent transparent #009efb;
    border-left-width: 2px;
  }

  .nav-tabs>li.open:not(.active)>a,
  .nav-tabs>li.open:not(.active)>a:hover,
  .nav-tabs>li.open:not(.active)>a:focus {
    background-color: #fafafa;
  }

  .nav-tabs.nav-tabs-solid {
    padding: 5px;
  }

  .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 5px;
  }

  .nav-tabs.nav-tabs-solid>li>a {
    border-left-width: 0 !important;
  }

  .nav-tabs-justified {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs-justified>li>a.active,
  .nav-tabs-justified>li>a.active:hover,
  .nav-tabs-justified>li>a.active:focus {
    border-width: 0 0 0 2px;
    border-left-color: #009efb;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1.12em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.75em;
  }

  .page-title {
    font-size: 1.2em;
    padding-top: 10px;
  }

  .card-title {
    font-size: 1.12em;
  }

  .blog-view .blog-title {
    font-size: 18px;
  }

  .widget h3 {
    font-size: 18px;
  }

  .my-video ul li {
    width: 80px;
  }

  .voice-call-avatar .call-avatar {
    width: 120px;
    height: 120px;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .header .has-arrow .dropdown-toggle>span:nth-child(2) {
    display: none;
  }

  .header .has-arrow .dropdown-toggle:after {
    display: none;
  }

  .btn-white {
    margin: 0px;
  }

  .login-wrapper .loginbox .login-right .forgotpass,
  .next-sign {
    display: block;
  }

  .remember-me {
    margin-bottom: 10px;
  }

  .login-right .btn-primary {
    font-size: 14px;
    padding: 7px 15px;
  }

  .account-subtitle {
    margin-bottom: 15px;
  }

  .login-wrap-bg {
    padding: 15px 20px 15px;
  }

  .login-wrapper .loginbox .login-right {
    padding: 20px;
  }

  .error-box h3 {
    font-size: 18px;
  }

  .error-box p {
    font-size: 14px;
  }

  .error-box img {
    margin-bottom: 25px;
  }

  .sucess-mail h4 {
    font-size: 22px;
  }

  .mobile-user-menu>a {
    color: #2e37a4;
  }

  .morning-img img {
    position: relative;
    width: 100%;
  }

  .good-morning-blk:before {
    display: none;
  }

  .morning-user {
    padding: 0;
  }

  .good-morning-blk {
    padding: 20px;
  }

  .morning-user h2 {
    font-size: 20px;
  }

  .morning-user p {
    font-size: 14px;
  }

  .page-header .breadcrumb {
    font-size: 14px;
  }

  .dash-content h4 {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .dash-content h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .patient-visit {
    flex-wrap: wrap;
  }

  .chat-user-total {
    margin: 10px 0px;
  }

  .wallet-widget .circle-bar>div canvas {
    width: 100px !important;
    height: 100px !important;
  }

  .steps-history li,
  .doctor-date-list li {
    font-size: 12px;
  }

  .doctor-date-list li span {
    padding-left: 5px;
  }

  .border-right-bg:before {
    display: none;
  }

  .border-right-bg {
    margin-bottom: 10px;
  }

  .active-doctor {
    display: block;
  }

  .weight-blk {
    margin-bottom: 5px;
  }

  .treat-box {
    display: block;
  }

  .user-imgs-blk {
    margin-bottom: 10px;
  }

  .user-imgs-blk img {
    margin-right: 8px;
  }

  .profile-user-img img {
    width: 90px;
  }

  .profile-edit-icon img {
    width: 25px;
  }

  .doctor-follows h5 {
    font-size: 12px;
  }

  .doctor-follows h4 {
    font-size: 16px;
  }

  .follow-group {
    padding: 0px 15px;
  }

  .follow-btn-group .btn-info {
    min-width: 115px;
    font-size: 14px;
    padding: 6px 15px;
  }

  .follow-btn-group {
    margin: 15px 0px;
  }

  .names-profiles h4,
  .tab-content-set li a {
    font-size: 16px;
  }

  .tab-content-set ul li a.active::after,
  .tab-content-set ul li a:hover::after {
    display: none;
  }

  .tab-content-set li {
    margin-bottom: 15px;
  }

  .tab-content-set {
    padding: 0;
  }

  .detail-personal {
    margin-bottom: 15px;
  }

  .doctor-submit .btn-primary {
    min-width: 125px;
    font-size: 12px;
    border-radius: 12px;
    padding: 7px 15px;
    margin-bottom: 8px;
    margin-right: 0px !important;
  }

  .invoice-counts h4 {
    display: inline-block;
  }

  .activity-list::before {
    display: none;
  }

  .timeline-group-blk {
    display: block;
  }

  .comman-activitys {
    padding: 12px;
    margin-top: 5px;
  }

  .activity-sub-list li {
    margin-bottom: 10px;
  }

  .latest-posts-act .post-thumb {
    float: inherit;
    margin-bottom: 15px;
  }

  .latest-posts-act .post-info {
    margin-left: 0;
  }

  .blog-grp-blk {
    flex-wrap: wrap;
  }

  .blog-img-blk {
    margin-bottom: 5px;
  }

  .blog-grp-blk span {
    margin-left: auto;
  }

  .grid-blog .blog-title a {
    font-size: 15px;
  }

  .chat-search-group {
    display: block;
  }

  .chat-search-list ul {
    margin-top: 10px;
  }

  .chat-search-list ul li:first-child {
    margin-left: 0;
  }

  .inputgroups input[type="text"] {
    padding-right: 125px;
  }

  .settings-btn.upload-files-avator .upload {
    width: 120px;
    font-size: 12px;
  }

  .mailview-header,
  .send-user.send-user-name h4 {
    display: block;
  }

  .sender-info {
    margin-bottom: 10px;
  }

  .send-user.send-user-name h4 span {
    display: inline-block;
    margin: 0;
  }

  .mail-attach .msg-sub-list li {
    margin-bottom: 10px;
  }

  .forward-send .btn-primary {
    margin-bottom: 10px;
    width: 100%;
  }

  .chat-user-group.clinic-user {
    display: block !important;
  }

  .call-list-add {
    padding: 0;
  }

  .meeting .join-video {
    height: 116px;
  }

  .join-video.single-user {
    width: 100%;
  }

  .join-video.single-user {
    height: auto;
  }

  .meet-icons .meet-items .meet-item>a {
    width: 35px;
    height: 35px;
  }

  .end-call-chat .btn-primary {
    min-width: 90px;
    margin: 0px 5px;
    padding: 6px 13px;
    font-size: 14px;
  }

  .meet-icons .meet-items .meet-item,
  .action-items .action-item {
    margin-right: 5px;
  }

  .card {
    width: 100%;
  }

  .patient-structure h3 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 575.98px) {
  .app-listing li {
    width: 100%;
  }

  .invoices-tabs ul li {
    margin-right: 0;
    display: block;
    margin-bottom: 14px;
  }

  .invoices-tabs ul li a {
    padding-bottom: 0;
  }

  .invoices-settings-btn {
    justify-content: start;
  }

  .invoices-create-btn .btn {
    font-size: 14px;
    padding: 10px;
    min-width: auto;
  }

  .invoice-info-card .invoice-item-one .invoice-info {
    text-align: left;
  }

  .invoice-item .invoice-details {
    text-align: left;
    float: left;
  }

  .invoice-info.invoice-info2 {
    text-align: left;
  }

  .invoice-total-footer h4 {
    font-size: 14px;
  }

  .nav-tabs.menu-tabs .nav-link {
    padding: 10px;
  }

  ul.nav.nav-tabs.menu-tabs {
    padding: 10px;
  }

  .invoice-setting-btn .btn {
    min-width: auto;
    padding: 10px;
  }

  .contact-cat {
    padding-right: 70px;
  }

  .user-menu {
    display: none;
  }

  .mobile-user-menu {
    display: block;
  }

  .search-box {
    display: none;
  }

  .filter-row {
    margin-bottom: 15px;
  }

  .page-wrapper>.content {
    padding: 15px;
  }

  .profile-view .profile-basic {
    margin-left: 0;
  }

  .profile-img-wrap {
    position: relative;
    margin: 0 auto;
  }

  .profile-view .profile-img-wrap {
    margin: 0 auto 10px;
    height: 100px;
    width: 100px;
  }

  .profile-view .profile-img {
    height: 100px;
    width: 100px;
  }

  .profile-view .profile-img .avatar {
    font-size: 24px;
    height: 100px;
    line-height: 100px;
    margin: 0;
    width: 100px;
  }

  .profile-info-left {
    text-align: center;
  }

  .profile-basic {
    margin-left: 0;
    margin-top: 15px;
  }

  .page-title {
    font-size: 18px;
  }

  .fc-toolbar .fc-right {
    display: inline-block;
    float: none;
    margin: 10px auto 0;
    width: 200px;
    clear: both;
  }

  .fc-toolbar .fc-left {
    float: none;
    margin: 0 auto;
    width: 200px;
  }

  .upload-text {
    font-size: 18px;
  }

  .call-duration {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: inherit;
  }

  .end-call {
    margin-top: 10px;
    position: inherit;
  }

  .chat-right .chat-content {
    max-width: 90%;
  }

  .chat-right .chat-body {
    padding-left: 0;
    padding-right: 0;
  }

  .chat-left .chat-content {
    max-width: 90%;
  }

  .chat-left .chat-body {
    padding-left: 0;
    padding-right: 0;
  }

  .chat-avatar .avatar {
    height: 20px;
    line-height: 20px;
    width: 20px;
  }

  .account-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .content {
    padding: 0 50px;
  }

  .custom-menu.navbar-nav>li>a {
    margin-left: 10px;
  }

  .error-box h1 {
    font-size: 50px;
  }

  .submit-btn {
    min-width: 150px;
  }

  .ui-kit-wrapper button {
    margin-bottom: 5px;
  }

  .pagination>li>a,
  .pagination>li>span {
    padding: 0.5rem !important;
  }

  .invoice-item-box {
    float: left;
  }
}

@media only screen and (max-width: 479px) {
  .compose-btn button {
    padding: 0.365rem 0.5rem;
  }

  .attachments li {
    float: none;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  right: -200% !important;
}

#lightgallery .img-thumbnail {
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  width: 250px !important;
}

/* start */
h2.profile-image {
  color: #333448;
  font-size: 14px;
  font-weight: 500;
}

.doctor-list td.ant-table-cell {
  font-weight: 550;
}

th.ant-table-cell {
  color: #333448 !important;
  font-size: 14px !important;
  font-weight: 650 !important;
}

/* PAGINATION */
.ant-pagination.ant-table-pagination.ant-table-pagination-right.css-dev-only-do-not-override-xu9wm8 {
  position: relative !important;
}

.ant-pagination-total-text {
  position: absolute !important;
  left: 0px !important;
}

/* Remove icon  */

.card-tabel .ant-table-thead {
  display: none !important;
}

.ant-table-thead :hover {
  white-space: nowrap;
}

.ant-table-tbody {
  white-space: nowrap;
  font-weight: 500;
}

.react-calendar {
  width: 100% !important;
  border: none;
  font-weight: 20;
  padding: .8em .0.5em;
  font-size: 12px;
  font-weight: 600;

}

.calendar thead {
  /* font-size: 1.2em; */
  font-weight: 600;
}

.input.form-check-input {
  margin-bottom: 5px !important;
}

.dropdown-toggle.btn {
  border-style: none !important;
}

li.ant-pagination-total-text {
  padding-left: 20px;
  font-weight: 500;
}

.custom-datepicker .ant-picker-dropdown {
  z-index: 9999 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.radial-bar-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ant-picker.css-dev-only-do-not-override-14wwjjs.form-control.datetimepicker {
  padding-top: 11px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 45px;
  border-radius: 10px;
  border: 2px solid rgba(46, 55, 164, 0.1) !important;
}

li.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
  background-color: #2e37a4;
  color: white;
  border: none;
}

li.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active:hover {
  background-color: #00d3c7;
  border: none;
  min-width: 32px;
}

.css-t3ipsp-control:focus {
  border-color: #2e37a4;
}

.css-t3ipsp-control:hover {
  border-color: #2e37a4;
}

.profile-table.custom-table thead tr th,
.profile-table.custom-table tbody tr td {
  font-size: 14px;
  color: #333548;
  font-weight: 500;
  padding: 10px
}

.invoice-inner-date {
  padding-top: 12px;

}

.circular-progress-bar-container {
  width: 20px !important;
  /* Set the desired width for the circle */
}

.ant-table-thead .ant-table-cell {
  background-color: transparent !important;
  font-weight: 600 !important;
}

.ant-table-thead .ant-table-cell:before {
  width: 0px !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell-row-hover,
.ant-table-wrapper .ant-table-tbody>tr>th.ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 14px !important;
  font-weight: 500 !important;
}

tr.ant-table-row.ant-table-row-level-0.ant-table-row-selected,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
  background-color: transparent !important;
}

.ant-table-wrapper .ant-table-tbody .ant-checkbox .ant-checkbox-inner {
  height: 1em !important;
  width: 1em !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00d3c7;
  border-color: #00d3c7;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #00d3c7 !important;
}

.ant-table-wrapper .ant-table {
  font-family: 'Poppins', sans-serif;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  background-color: white;
  padding: 10px 0;
  margin: 0 !important;
}

.card-room-dashboard .card {
  margin-bottom: 0 px !important;
}

.room-dashoard-home .page-wrapper {
  position: relative;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent white */
  z-index: 1000;
  /* Ensure it's above everything */
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  /* Ensure it's above the overlay */
}

/* Dropdown Color  */

.dropdown-color .ant-select-show-search:where(.css-dev-only-do-not-override-xu9wm8).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer;
}

.dropdown-color.Upcoming .ant-select-selector:focus {
  outline: none;
  border: 0;

}

.dropdown-color.Upcoming .ant-select-selector {
  background-color: rgba(13, 196, 253, 0.4);
  color: "black";
  cursor: "pointer";
  border: 0;
}

.dropdown-color.Completed .ant-select-selector {
  background-color: rgba(25, 135, 84, 0.5);
  color: "black";
  cursor: "pointer";
  border: 0;

}

.dropdown-color.Cancelled .ant-select-selector {
  background-color: rgba(255, 0, 24, 0.3);
  color: "black";
  cursor: "pointer";
  border: 0;

}
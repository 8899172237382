.loader {
    position: relative;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background: linear-gradient(#f07d6ede, #84cdfabe, #5ad1cdc1);
    animation: animate 1.2s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.loader span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#f07d6e91, #84cdfaae, #5ad1cd88);
}

.loader span:nth-child(1) {
    filter: blur(5px);
}

.loader span:nth-child(2) {
    filter: blur(10px);
}

.loader span:nth-child(3) {
    filter: blur(25px);
}

.loader span:nth-child(4) {
    filter: blur(50px);
}

.loader:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: solid white 10px;
    border-radius: 50%;
}

.ant-modal {
    width: fit-content !important;

}

.calander-modal {
    width: 100% !important;

}